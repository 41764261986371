import ReactApexChart from "react-apexcharts";
import React, { useEffect, useState, memo } from "react";
import { usePerformanceContext } from "../../Context/PerformanceProvider";
function LineGraph({ height, position }) {
  let PerformanceContext = usePerformanceContext();
  let [result, setResult] = useState([]);

  useEffect(() => {
    //if data has changed, then update state
    if (
      PerformanceContext.propData !== undefined &&
      PerformanceContext.propData !== null
    ) {
      setResult(PerformanceContext.propData);
    }
  }, [PerformanceContext.propData]);

  const seriesFunc = (param) => {
    let expected = {
      name: "Daily Performance",
      data: [],
    };
    let points = {
      name: "Last 5 Business Days",
      data: [],
    };
    let def = () => [{ ...expected }, { ...points }];
    if (param == undefined || param == null) {
      return def();
    }
    if (Array.isArray(param) && param.length > 0) {
      let daily_dat = [];
      let lastbuss_dat = [];
      for (const item of param) {
        daily_dat.push(item.PerfDaily);
        lastbuss_dat.push(item.Last5DaysPerfPercent);
      }
      points.data = lastbuss_dat;
      expected.data = daily_dat;
      return def();
    }
    return def();
  };

  const optFunc = (param) => {
    let options = {
      colors: ["#3347E6", "#EF5DA8"],
      grid: {
        row: {
          opacity: 0.5,
        },
        column: {
          opacity: 0.5,
        },
      },
      legend: {
        position: "top",
      },
      yaxis: {
        min: 0,
        decimalsInFloat: 0,
        forceNiceScale: true,
        labels: {
          style: {
            color: "#008FFB",
          },
        },
      },

      chart: {
        toolbar: {
          show: false,
        },
      },
      markers: {
        size: 3,
        hover: {
          sizeOffset: 6,
        },
      },
      stroke: {
        curve: "smooth",
        width: [2, 2],
        dashArray: [2, 0],
      },
      xaxis: {
        type: "string",
        categories: ["01/06", "02/06", "03/06", "04/06"],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };

    let def = () => options;
    if (param == undefined || param == null) {
      return def();
    }
    if (Array.isArray(param) && param.length > 0) {
      let opt_dat = [];
      let maxValues = param.map(item => Math.max(item.PerfDaily, item.Last5DaysPerfPercent)); // Adjust this based on your data structure

      for (const item of param) {
        opt_dat.push(
          item.Date.substr(
            item.Date.indexOf("-") + 1,
            item.Date.indexOf("T") - 5
          ).replace("-", "/")
        );
      }

      options.xaxis.categories = opt_dat;

      // Calculate tickAmount dynamically based on the maximum value
    let maxValue = Math.max(...maxValues); // Adjust this based on your data structure
    let tickAmount = Math.ceil(maxValue / 10) + 1; // Calculate the tick amount
    options.yaxis.max = maxValue;
    options.yaxis.tickAmount = tickAmount;

      return def();
    }
    return def();
  };

  return (
    <div
      style={{
        textAlign: "center",
        paddingBottom: position,
      }}
    >
      <ReactApexChart
        options={optFunc(result)}
        series={seriesFunc(result)}
        type="line"
        height={height}
      />
    </div>
  );
}

export default memo(LineGraph);
