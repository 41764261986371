import React, { useState, useEffect } from 'react';
import { UpdateLabel, UpdateDiv } from "./Windows/styles";
import { addDays } from "date-fns";

const Timer = (props) => {
  const { Time, Text } = props;
  const futureDate = addDays(new Date(Time).getTime(), 7).getTime();
  const [seconds, setSeconds] = useState(futureDate - new Date().getTime()); // Set the initial countdown time in milliseconds

  useEffect(() => {
    const interval = setInterval(() => {
      // Calculate the remaining time based on the current time
      const remainingTime = futureDate - new Date().getTime();

      if (remainingTime > 0) {
        setSeconds(remainingTime);
      } else {
        clearInterval(interval);
        setSeconds(0);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [futureDate]); // Only re-run the effect when futureDate changes

  const formatTime = (timeInMillis) => {
    const days = Math.floor(timeInMillis / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeInMillis % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeInMillis % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeInMillis % (1000 * 60)) / 1000);

    return days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
  };

  return (
    <UpdateDiv>
      <UpdateLabel className={"Timer"} style={{marginLeft: '1%' }}>
        {Text} <span>{formatTime(seconds)}</span>
      </UpdateLabel>
    </UpdateDiv>
  );
}

export { Timer }


