import styled from "styled-components";
import {
  MainNumber,
  WindowTitle,
} from "../Windows/styles";

const Title = styled.p`
    font-weight: 600;
    font-size: 47px;
    text-align: center;

  @media (max-width: 1550px) {
    font-size: 40px;
    margin-bottom: 25px;
  }

  @media (max-width: 1266px) and (min-width: 992px) {
    margin-bottom: 15px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 35px;
  text-align: center;

  @media (max-width: 1550px) {
    font-size: 30px;
  }

  @media (max-width: 1266px) and (min-width: 992px) {
    font-size: 20px;
  }
`;

const RunningQuarterValuesContainer = styled.div`
`;

const Number = styled.span`
  font-weight: 600;

  @media (max-width: 1266px) and (min-width: 992px) {
    font-size: 25px;
  }
`;

const Value = styled.p`
font-weight: 700;
font-size: 90px;
text-align: center;

`;

const Desc = styled.p`
font-weight: 500;
font-size: 35px;
text-align: center;
margin-top: -35px;

`;

const SubValue = styled.p`
font-weight: 700;
font-size: 50px;
text-align: center;
margin-top: -15px;

`;

const SubDesc = styled.p`
font-weight: 500;
font-size: 35px;
text-align: center;
margin-top: -25px;

@media (max-width: 1090px) and (min-width: 992px) {
  font-size: 30px;
}
`;


/* DL Card Comp Styles */

const DLValue = styled(MainNumber)`
font-size: 80px;
text-align: right;
line-height: 100%;
margin-left: 25px;

  @media (max-width: 1322px) and (min-width: 992px) {
    font-size: 55px;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  @media (max-width: 850px) {
    font-size: 75px;
}
`

const DLTitle = styled(WindowTitle)`
  text-align: left;
  margin-left: -25px;
  transform: translate(10px, 10px);
  margin-left: 15px;

  @media (max-width: 1322px) and (min-width: 992px) {
    font-size: 30px;
    text-wrap: nowrap;
    margin-left: 5px;
  }

  @media (max-width: 850px) {
      margin-left: 0px;
  }
`


/* End DL Card Comp Styles */

export { RunningQuarterValuesContainer, Title, Row, Number, Value, Desc, SubValue, SubDesc, DLValue, DLTitle };