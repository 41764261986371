import React from "react"
import { useEffect, useState, useRef} from "react"
import {DateRange} from "react-date-range"
import format from "date-fns/format"
import { addDays, startOfWeek } from "date-fns";
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const DateRangeComp = ({setStart}) => {
  
    const [range, setRange] = useState([
        {
            startDate: addDays(new Date(), -7),
            endDate: new Date(),
            key: "selection",
        }
    ])
    setStart(range)
   
    const getNearestFriday = (date) => {
        const startOfWeekDate = startOfWeek(date, { weekStartsOn: 5 });
        return addDays(startOfWeekDate, 5);
      };
    const [open, setOpen] = useState(false)

    const refOne = useRef(null)

    useEffect(() => { 
        document.addEventListener("keydown", hideOnEscape, false)
        document.addEventListener("click", clickOnOutside, false)
    },[])

    const hideOnEscape = (e) => {
        if(e.key === "Escape"){
            setOpen(false)
            document.querySelector(".caldendarDark").classList.add("hidden")
        }
    }

    const clickOnOutside = (e) => {
        if(refOne.current && !refOne.current.contains(e.target)){
            setOpen(false) 
            document.querySelector(".caldendarDark").classList.add("hidden")
        }
    }

    let monthsShow = window.matchMedia("(max-width: 1100px)").matches ? 1 : 2 // work only after page refresh

    return (<div className = "calendarWrap">
            <div className="caldendarDark hidden"></div>
            <div ref={refOne}>
                <input
                    value = {` ${format(range[0].startDate, "MM/dd/yyyy")} to ${format(range[0].endDate, "MM/dd/yyyy")} `}
                    readOnly
                    className = "inputBox"
                    onClick = {() => {
                        setOpen(open => !open); 
                        document.querySelector(".caldendarDark").classList.remove("hidden")}}
                    placeholder="Choose period"
                />

                {open && 
                    <DateRange
                        onChange={item => setRange([item.selection])}
                        editableDateInputs={true}
                        moveRangeOnFirstSelection={false}
                        showDateDisplay={false}
                        
                        ranges={range}
                        months={monthsShow}
                        direction="horizontal"
                        className = "calendarElement"
                    />
                }
            </div>
        </div>)
    
}

export default DateRangeComp