import styled from "styled-components";

//Windows

const Window = styled.div`
  margin: 40px 0 0 20px;
  padding: 30px;
  max-width: 700px;
  min-width: 500px;
  height: 350px;
  background: #ffffff;
  box-shadow: 11.5px 11.5px 35px 11.5px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  justify-content: center;
  display: grid;

  font-family: "Open Sans", sans-serif;
  font-style: normal;
  color: #324395;

  @media (max-width: 1550px){
    min-width: 380px;
  }

  @media (max-width: 991px) {
    margin: 40px auto 0;
  }

  @media (max-width: 900px){
    transform: scale(0.8);
    margin-top: -20px;
  }

  @media (max-width: 700px){
    margin-bottom: 15px;
  }
`;

const SearchBox = styled.div`
  margin: 30px auto 30px 50px;
  padding: 13px;
  width: 550px;
  height: 70px;
  left: 500px;
  top: 48px;
  background: #ffffff;
  border-radius: 25px;
  justify-content: center;
`;

const MainNumber = styled.p`
  font-weight: 700;
  font-size: 97px;
  text-align: center;
  margin: auto;
`;

const WindowTitle = styled.p`
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  margin:  -25px auto 0;
`;

const WindowSubTitle = styled.p`
  font-weight: 700;
  font-size: 50px;
  text-align: center;
  margin:  -25px auto 0;
`;

const WindowSubNumber = styled.span`
font-size: 70px;
`;

const LateOrderWindowTitle = styled.p`
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  margin:  0px auto 0px auto;
`;

const UpdateLabel = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  text-align: center;
  color: #fff;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: auto;

`;

const UpdateDiv = styled.div`
  padding: 30px 30px 0px;

  @media (max-width: 670px){
    padding-bottom: 15px;
  }
`;

const SearchText = styled.p`
  margin: 0;
  display: flex;
  align-items: center;
  vertical-align: middle;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  color: #aaa9a9;
`;

/*TOOLTIP. TO REMOVE, WHEN SEARCH IS IMPLEMENTED

const Tooltip = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: #fff;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
`;

/*END OF TOOLTIP*/




//Settings window

const LargeWindowTitle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  color: #324395;
  margin:  20px auto 0;

  @media (max-width: 1023px){
    margin-top: 35px;
  }
`;

const LargeWindowSubtitle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  color: #324395;
  margin: 42px auto;
`;

const LargeWindow = styled.div`
margin: 75px auto 50px;
padding: 25px 30px 95px;
width: 814px;
background: #ffffff;
box-shadow: 11.5px 11.5px 35px 11.5px rgba(0, 0, 0, 0.25);
border-radius: 35px;
justify-content: center;
position: relative;

@media (max-width: 1023px){
  width: 614px;
  margin-top: 75px;
  margin-bottom: 20px;
  margin-left: -25px;
}

@media (max-width: 800px){
  width: 590px;
  margin-left: -30px;
}

@media (max-width: 767px){
  margin-left: 4.5%;
  width: 530px;
}

@media (max-width: 425px){
  margin-top: 100px;
  width: 570px;
}

@media (max-width: 410px){
  width: 530px;
}

@media (max-width: 360px){
  width: 510px;
  margin-left: 7%;
}

`

const InputsWrap = styled.form`
display: flex;
flex-direction: column;
margin: 0 auto;
`

const FieldWrap = styled.div`
  position: relative;
  margin: 0 auto;
`

const WindowLabel = styled.label`
  color: #AAA9A9;
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  position: absolute;
  left: 33px;
  top: 20px;

  @media (max-width: 1023px){
    display: none !important;
  }
`;

const WindowSelect = styled.select`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  color: #444444;
  padding: 20px 32px 20px 77px;
  margin: 0 auto 36px;
  width: 521px;
  height: 69px;
  background: #FFFFFF;
  box-shadow: 10px 10px 50px 20px rgba(39, 56, 131, 0.1);
  border-radius: 100px;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  @media (max-width: 1023px){
    width: 421px;
  }

  @media (max-width: 767px){
    transform: scale(1.15);
  }

  @media (max-width: 360px){
    width: 400px;
  }

`

const WindowInput = styled.input`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  color: #444444;
  margin: 0 auto 36px;
  padding: 22px 32px;
  width: 521px;
  height: 69px;
  background: #FFFFFF;
  box-shadow: 10px 10px 50px 20px rgba(39, 56, 131, 0.1);
  border-radius: 100px;
  border: none;
  outline: none;

  ::placeholder {
    color: #AAA9A9;
  }

  @media (max-width: 1023px){
    width: 421px;
    padding-left: 44px !important;
  }

  @media (max-width: 767px){
    transform: scale(1.15);
  }

  @media (max-width: 360px){
    width: 400px;
  }

  //Hide Arrows From Input Number

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type=number] {
    -moz-appearance: textfield;
  }

`
const WindowButton = styled.button`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 120%;
  color: #fff;
  padding: 20px 0;
  margin: 0 auto;
  text-alight: center;
  background: #2A47CC;
  border-radius: 100px;
  border: none;
  width: 521px;
  height: 70px;
  letter-spacing: 2px;

  @media (max-width: 1023px){
    width: 421px;
  }

  @media (max-width: 767px){
    transform: scale(1.15);
    margin-top: 40px;
  }

  @media (max-width: 360px){
    width: 400px;
  }
`
const CalendarIconWrap = styled.div`
  position: absolute;
  top: 19px;
  left: 31px;
  color: #AAA9A9;

  @media (max-width: 1023px){
    top: 265px;
    left: 133px;
  }
  @media (max-width: 800px){
    left: 120px;
  }
  @media (max-width: 767px){
    top: 307px;
    left: 70px;
    z-index: 100;
  }
  @media (max-width: 425px){
    top: 267px;
    left: 85px;
  }
  @media (max-width: 410px){
    top: 307px;
    left: 70px;
  }
`

/* Scheduling window */

const SchedulingDataWindow = styled.div`
margin: 100px auto 0;
padding: 30px;
width: 814px;
height: 752px;
background: #ffffff;
box-shadow: 11.5px 11.5px 35px 11.5px rgba(0, 0, 0, 0.25);
border-radius: 35px;
justify-content: center;
position: relative;
`

export {
  Window,
  SearchBox,
  MainNumber,
  WindowTitle,
  WindowSubNumber,
  WindowSubTitle,
  UpdateLabel,
  SearchText,
  UpdateDiv,
  LateOrderWindowTitle,

  LargeWindow,
  InputsWrap,
  WindowSelect,
  WindowInput,
  FieldWrap,
  WindowButton,
  WindowLabel,
  LargeWindowTitle,
  LargeWindowSubtitle,
  CalendarIconWrap,

  SchedulingDataWindow,
};
