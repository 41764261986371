import React, { useState } from "react";
import LineGraph from "../../PerformanceDash/Components/Charts/LineChart";
import BarGraph from "../../PerformanceDash/Components/Charts/BarChart";
import Reacttable from "../../PerformanceDash/Components/Tables/Table.js";
import { addDays } from "date-fns";
import DateRangeComp from "../DateRangeComp";
import TrendIcon from "../../assets/images/TrendIcon.svg";
import ArrowsIcon from "../../assets/images/ArrowsIcon.svg";
import CloseArrowsIcon from "../../assets/images/CloseArrowsIcon.svg";
import ChartIcon from "../../assets/images/ChartIcon.svg";
import HistoryIcon from "../../assets/images/HistoryIcon.svg";
 import { PerformanceProvider } from "../../PerformanceDash/Context/PerformanceProvider";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function GetDate(date) {
  return (
    doubleDigit(date.getMonth() + 1) +
    "/" +
    doubleDigit(date.getDate().toString()) +
    "/" +
    date.getFullYear().toString()
  );
}

function doubleDigit(value) {
  if (value.toString().length === 1) {
    let result = `${"0".repeat(1)}${value.toString()}`;
    return result;
  } else {
    return value;
  }
}
function ProductionWindow() {
  let [value, setValue] = useState(8);
  const OpenSlider = (slide) => {
    const swiper = document.querySelector(".swiper").swiper;
    swiper.slideTo(slide);

    document
      .querySelector(".production__graphs-block")
      .classList.add("graphs-opacity");
    document.querySelector(".graphs-slider").classList.remove("graphs-opacity");
    document.querySelector(".staticElement").classList.add("close-menu");
    document
      .querySelector(".production__block-top")
      .classList.add("production__block-top-large");
  };

  const CloseSlider = () => {
    document.querySelector(".graphs-slider").classList.add("graphs-opacity");
    document
      .querySelector(".production__graphs-block")
      .classList.remove("graphs-opacity");
    document.querySelector(".staticElement").classList.remove("close-menu");
    document
      .querySelector(".production__block-top")
      .classList.remove("production__block-top-large");
  };
  const [start, setStart] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    },
  ]);


  return (
    <div>
      <div className="production__block-top">
        <div className="production__right-column">
          <div>
            <DateRangeComp setStart={setStart} />
          </div>

          <select onChange={(event) => setValue(event.target.value)}>
            <option value="8">STD</option>
            <option value="2">DF</option>
            <option value="0">LIN</option>
            <option value="6">OEM</option>
          </select>
        </div>
      </div>
      <PerformanceProvider
        startDate={GetDate(start[0].startDate)}
        endDate={GetDate(start[0].endDate)}
        depId={value}
      >
        <div className="production__graphs-block">
          <div className="production__graph">
            <div className="graph-title">
              <div>
                <img src={TrendIcon} alt="trend"/>
                <span>Daily and Average Performance</span>
              </div>
              <img
                onClick={() => {
                  OpenSlider(0);
                }}
                src={ArrowsIcon}
                alt="arrow"
              />
            </div>
       
            <LineGraph
              height={350}
              pos={"bottom"}
            />
            {/* Graph here */}
          </div>
          <div className="production__graph">
            <div className="graph-title">
              <div>
                <img src={ChartIcon} alt="chart"/>
                <span>Shift performance</span>
              </div>
              <img
                onClick={() => {
                  OpenSlider(1);
                }}
                src={ArrowsIcon}
                alt="arrow"
              />
            </div>
            <BarGraph
              height={350}
              departmentId={value}
              position={"bottom"}
            />
           
          </div>
          <div className="production__graph">
            <div className="graph-title">
              <div>
                <img src={HistoryIcon} alt="history"/>
                <span>Performance History</span>
              </div>
              <img
                onClick={() => {
                  OpenSlider(2);
                }}
                src={ArrowsIcon}
                alt="arrow"
              />
            </div>
            <Reacttable
              departmentId={value}
              resolution={"small"}
            />
          </div>
        </div>

        <div className="graphs-slider graphs-opacity">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            initialSlide={0}
            pagination={{ clickable: true }}
            spaceBetween={50}
            slidesPerView={1.36}
            centeredSlides={true}
            breakpoints={{
              320: {
                slidesPerView: 1.05,
              },
              1301: {
                slidesPerView: 1.15,
              },
              1900: {
                slidesPerView: 1.36,
              },
            }}
          >
            <SwiperSlide className="graph-slide">
              <div className="production__graph" style={{ height: "630px" }}>
                <div className="graph-title">
                  <div>
                    <img src={TrendIcon} alt="trend"/>
                    <span>Daily and Average Performance</span>
                  </div>
                  <div className="graph-close">
                    <span>
                      Return to <br /> small view
                    </span>
                    <img onClick={CloseSlider} src={CloseArrowsIcon} alt="close"/>
                  </div>
                </div>
                <LineGraph
                  height={505}
                  startDate={GetDate(start[0].startDate)}
                  endDate={GetDate(start[0].endDate)}
                  departmentId={value}
                  position={"30px"}
                />

                {/* Graph here */}
              </div>
            </SwiperSlide>
            <SwiperSlide className="graph-slide" >
              <div className="production__graph" style={{ height: "630px" }}>
                <div className="graph-title">
                  <div>
                    <img src={ChartIcon} alt="chart"/>
                    <span>Shift performance</span>
                  </div>
                  <div className="graph-close">
                    <span>
                      Return to <br /> small view
                    </span>
                    <img onClick={CloseSlider} src={CloseArrowsIcon} alt="close"/>
                  </div>
                </div>
                <BarGraph
                  height={480}
                  startDate={GetDate(start[0].startDate)}
                  endDate={GetDate(start[0].endDate)}
                  departmentId={value}
                  position={"30px"}
                />

                {/* Graph here */}
              </div>
            </SwiperSlide>
            <SwiperSlide className="graph-slide">
              <div className="production__graph" style={{ height: "630px" }}>
                <div className="graph-title">
                  <div>
                    <img src={HistoryIcon} alt="history"/>
                    <span>Performance History</span>
                  </div>
                  <div className="graph-close">
                    <span>
                      Return to <br /> small view
                    </span>
                    <img onClick={CloseSlider} src={CloseArrowsIcon} alt="close"/>
                  </div>
                </div>
                <Reacttable
                  departmentId={value}
                  position={"30px"}
                  resolution={"full"}
                />

                {/* Graph here */}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </PerformanceProvider>
    </div>
  );
}

export default ProductionWindow;
