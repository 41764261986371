import React from "react";
import {
  prepTiledWindowsData,
  WindowTitles,
} from "../ComponentsData/TiledWindowData";
import { Container, Row, Col } from "react-bootstrap";
import { Window, WindowTitle, Tile, TileText } from "./styles";
import { useManufacturingContext } from "../../Context/manufacturingContext";
import { useAuth } from "react-oidc-context";

function TiledWindows() {
  let ManufacturingContext = useManufacturingContext();

  return (
    <>
      <Container fluid>
        <Row>
          {prepTiledWindowsData(
            ManufacturingContext.propData
          ).tiles.map((tile, index) => (
            <Col key={index} style={{ padding: 0 }}>
              <Tile>
                <TileText>
                  {tile.department} <br />
                  {Math.ceil(tile.leadTime / 5)} weeks
                </TileText>
              </Tile>
            </Col>
          ))}
        </Row>
      </Container>
      <WindowTitle>{WindowTitles.LeadTimeReport}</WindowTitle>
    </>
  );
}

export { TiledWindows };
