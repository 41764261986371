import React, { useState, useEffect } from "react";
import { ApexOptions } from "apexcharts";
import axios from "axios";
import { SGD_API_HOST } from "../../../env";
import ReactApexChart from "react-apexcharts";
import dayjs from "dayjs";

interface IBilling {
  Date: Date;
  Low: number;
  Open: number;
  Adj: number;
  High: number;
}

interface IHistory {
  Billing: IBilling[];
  Backlog: number;
}

interface IDate {
  startDate: Date;
  endDate: Date;
}

function CandleGraph({ date, isChecked, height }: { date: IDate; isChecked: any, height: number }) {
  let [result, setResult] = useState<IHistory>();
  let [start, setStart] = useState(date.startDate);
  let [end, setEnd] = useState(date.endDate);
  let [isLoading, setLoading] = useState(false);
  useEffect(() => {
    if (start !== date.startDate || end !== date.endDate) {
      setStart(date.startDate);
      setEnd(date.endDate);
      setLoading(false);
    }
    fetchData(
      (history: IHistory) => {
        setResult(history);
      },
      (error: Error) => {
        console.error("Error:", error);
      },
      { startDate: start, endDate: end }
    );
    if (!isLoading) {
      setLoading(true);
    }
  }, [isLoading, date.startDate, date.endDate, start, end]);

  function fetchData(
    callback: (data: IHistory) => void,
    errorCallback: (error: Error) => void,
    params?: any
  ): void {
    axios
      .get<IHistory>(SGD_API_HOST + "/api/History/get-history", { params })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
  }

  const seriesFunc = (param: IHistory): any[] => {
    if (!param || param.Billing.length === 0) {
      return [];
    }

    return param.Billing.map((item) => ({
      x: item.Date,
      y: [item.Open, item.High, item.Low, item.Adj],
    }));
  };

  const valFormatter = (value) => {
    return (value + result.Backlog).toString();
  };

  function plainVal(value) {
    return value;
  }

  const GetFormattedValue = value => +value >= 1000 ? (+value / 1000).toFixed(2) + 'M' : value + 'K'

  let options: ApexOptions = {
    colors: ["#4285f4"],
    plotOptions: {
      candlestick: {
        colors: {
          upward: "#4285f4",
          downward: "#ffffff",
        },
        wick: {
          useFillColor: false,
        },
      },
    },
    series: [
      {
        name: "candle",
        data: seriesFunc(result),
      },
    ],
    chart: {
      height: 600,
      type: "candlestick",
    },
    annotations: {
      xaxis: [
        {
          x: "Oct 06 14:00",
          borderColor: "#4285f4",
          label: {
            borderColor: "#4285f4",
            style: {
              fontSize: "12px",
              color: "#fff",
              background: "#4285f4",
            },
            orientation: "horizontal",
            offsetY: 7,
            text: "Annotation Test",
          },
        },
      ],
    },
    tooltip: {
      enabled: true,
      x: {
        format: "dd/MM/yy",
      },
    },
    xaxis: {
      type: "category",
      categories: ["01/06", "02/06", "03/06", "04/06"],
      labels: {
        formatter: function (val) {
          return dayjs(val).format("YYYY/MM/DD");
        },
      },
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },

      labels: {
        formatter: (value) => {
          return GetFormattedValue(isChecked ? valFormatter(value) : plainVal(value))
        },
          
      },
    },

    stroke: {
      show: true,
      colors: ["#4285f4"],
      width: 2,
    },
  };
  
  if (window.matchMedia("(max-width: 600px)").matches) // work only after page refresh
    height = height / 2;

  return (
    <>
      <ReactApexChart
        options={options}
        series={options.series}
        type="candlestick"
        height={height}
      />
    </>
  );
}

export default CandleGraph;
