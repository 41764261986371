import React, { useState } from "react";
import { addDays } from "date-fns";
import CandleGraph from "../../PerformanceDash/Components/Charts/CandleChart";
import DateRangeComp from "../../PerformanceDash/Components/Charts/HistoryDateRange";
import CloseArrowsIcon from "../../assets/images/CloseArrowsIcon.svg";
import TrendIcon from "../../assets/images/TrendIcon.svg";
import ArrowsIcon from "../../assets/images/ArrowsIcon.svg";
import LineGraphComp from "../../Components/CardsComponents/LineGraphComp";
import { LineGraphProvider } from "../../Context/lineGraphContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function ChartWindow() {
  const [start, setStart] = useState([
    {
      startDate: addDays(new Date(), -84),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  function GetDate(date) {
    return (
      doubleDigit(date.getMonth() + 1) +
      "/" +
      doubleDigit(date.getDate().toString()) +
      "/" +
      date.getFullYear().toString()
    );
  }

  function doubleDigit(value) {
    if (value.toString().length === 1) {
      let result = `${"0".repeat(1)}${value.toString()}`;
      return result;
    } else {
      return value;
    }
  }

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const OpenSlider = (slide) => {
    const swiper = document.querySelector(".swiper").swiper;
    swiper.slideTo(slide);

    document
      .querySelector(".production__graphs-block")
      .classList.add("graphs-opacity");
    document.querySelector(".graphs-slider").classList.remove("graphs-opacity");
    document.querySelector(".staticElement").classList.add("close-menu");
    document
      .querySelector(".production__block-top")
      .classList.add("production__block-top-large");
  };

  const CloseSlider = () => {
    document.querySelector(".graphs-slider").classList.add("graphs-opacity");
    document
      .querySelector(".production__graphs-block")
      .classList.remove("graphs-opacity");
    document.querySelector(".staticElement").classList.remove("close-menu");
    document
      .querySelector(".production__block-top")
      .classList.remove("production__block-top-large");
  };

  return (
    <>
      <div className="chart__block-top production__block-top">
        <div className="toggle-column" style={{ justifyContent: "end" }}>
          {isChecked ? (
            <span className="toggleName">Absolute</span>
          ) : (
            <span className="toggleName">Relative</span>
          )}
          <input
            type="checkbox"
            id="switch"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="switch">Toggle</label>
        </div>
        <div
          className="production__right-column chart__right-column"
          style={{ justifyContent: "end" }}
        >
          <div>
            <DateRangeComp setStart={setStart} initialStartDate={new Date(GetDate(start[0].startDate))}/>
          </div>
        </div>
      </div>

      <div className="production__graphs-block">
        <div className="production__graph">
          <div className="graph-title">
            <div>
              <span> The history of bookings and billings</span>
            </div>
            <img
              onClick={() => {
                OpenSlider(0);
              }}
              src={ArrowsIcon}
              alt="arrow"
            />
          </div>

          <CandleGraph
            date={{
              startDate: GetDate(start[0].startDate),
              endDate: GetDate(start[0].endDate),
            }}
            isChecked={isChecked}
            height={600}
          />
        </div>
        <div className="production__graph">
          <div className="graph-title">
            <div>
              <span>The DL % and DL Base Graph</span>
            </div>
            <img
              onClick={() => {
                OpenSlider(1);
              }}
              src={ArrowsIcon}
              alt="arrow"
            />
          </div>

          <LineGraphProvider url={'getDLValues'} endDate={new Date(GetDate(start[0].endDate))} startDate={new Date(GetDate(start[0].startDate))}>
            <LineGraphComp height={'600'} showFullDate={true}/>
          </LineGraphProvider>
        </div>
      </div>

      <div className="graphs-slider graphs-opacity">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          initialSlide={0}
          pagination={{ clickable: true }}
          spaceBetween={50}
          slidesPerView={1.36}
          centeredSlides={true}
          breakpoints={{
            320: {
              slidesPerView: 1.05,
            },
            1301: {
              slidesPerView: 1.15,
            },
            1900: {
              slidesPerView: 1.36,
            },
          }}
        >
          <SwiperSlide className="graph-slide">
            <div className="production__graph">
              <div className="graph-title">
                <div>
                  <span> The history of bookings and billings</span>
                </div>
                <div className="graph-close">
                  <span>
                    Return to <br /> small view
                  </span>
                  <img onClick={CloseSlider} src={CloseArrowsIcon} alt="close" />
                </div>
              </div>
              <CandleGraph
                date={{
                  startDate: GetDate(start[0].startDate),
                  endDate: GetDate(start[0].endDate),
                }}
                isChecked={isChecked}
                height={600}
              />
            </div>
          </SwiperSlide>

          <SwiperSlide className="graph-slide" >
            <div className="production__graph">
              <div className="graph-title">
                <div>
                  <span> The DL % and DL Base Graph</span>
                </div>
                <div className="graph-close">
                  <span>
                    Return to <br /> small view
                  </span>
                  <img onClick={CloseSlider} src={CloseArrowsIcon} alt="close" />
                </div>
              </div>
              <LineGraphProvider url={'getDLValues'} endDate={new Date(GetDate(start[0].endDate))} startDate={new Date(GetDate(start[0].startDate))}>
                <LineGraphComp height={'600'} showFullDate={true}/>
              </LineGraphProvider>
            </div>
          </SwiperSlide>

        </Swiper>
      </div>

    </>
  );
}

export default ChartWindow;
