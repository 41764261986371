import React from 'react';
import { useManufacturingContext, ManufacturingData } from "../../Context/manufacturingContext";

const WindowTitles = {
  AvgPerformancePerWeek: "Avg Perform/week",
  LeadTimeReport: "Lead Time Report",
};

interface TileData {
  id: string;
  entryDate: Date;
  department: string;
  leadTime: number;
};

interface TiledWindowData {
  tiles: Array<TileData>;
  dateRecieved: Date;
};

const defTiledWindowData:TiledWindowData = {
    tiles:new Array(),
    dateRecieved: new Date("2022-04-18T00:00:00")
};

const prepTiledWindowsData = (data: ManufacturingData | null) => {
  let result: TiledWindowData = {...defTiledWindowData};
  result.tiles=new Array()
  if (data === null || data === undefined) {
    return result;
  }
  data.LeadTimes.map((tile) => result.tiles.push({
      id:tile.Id,
      entryDate: tile.EntryDate,
      department: tile.Department,
      leadTime: tile.LeadTime
    }));
  return result;
};

const TiledWindowData = [
  {
    Grilles: "",
    Ladders: "",
    DF: "",
    Linear: "",
    title: "Avg Perform/week",
  },
  {
    ALB: "0 week",
    DF: "1 week",
    STD: "2 weeks",
    title: "Lead Time Report",
  },
];

export { prepTiledWindowsData, TiledWindowData, WindowTitles };
