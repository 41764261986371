import React from "react";
import {
    DLValue,
    DLTitle,
} from "./styles";

function DLCardComp(props) {

    const { title, DL, baseDL, role } = props
    const getValue = (value) => (role === "Mechanic") ? (value * 10).toFixed(2) : (value * 100).toFixed(2) + "%"
    const getText = (value) => (role === "Mechanic") ? value.replace("%", "") : value

    return (
        <div>
            <DLTitle className={"DLCardTitle"} style={{ textAlign: "left", transform: "translate(0px, 0px)", marginLeft: "0px" }}>
                {title}
            </DLTitle>
            <table>
                <tr>
                    <td><DLValue className={"DLCardNumber"}>{getValue(DL)}</DLValue></td>
                    <td>
                        <DLTitle className={"DLCardText"}>
                            {getText("DL %")}
                        </DLTitle>
                    </td>
                </tr>
                <tr>
                    <td><DLValue className={"DLCardNumber"} style={{ lineHeight: "110%" }}>{getValue(baseDL)}</DLValue></td>
                    <td>
                        <DLTitle className={"DLCardText"}>
                            {getText("DL Base %")}
                        </DLTitle>
                    </td>
                </tr>

                <tr>
                    <td><DLValue className={"DLCardNumber"}>{getValue(DL - baseDL)}</DLValue></td>
                    <td>
                        <DLTitle className={"DLCardText"}>
                            {getText("Δ DL %")}
                        </DLTitle>
                    </td>
                </tr>
            </table>
        </div>
    );
}

export { DLCardComp };