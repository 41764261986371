import React from "react";
import axios from "axios";
import { SGD_API_HOST } from "../../../env";

function InputMarker(conf) {
  let handleClick = (event, id, perfId) => {
    conf.setInputId((prevState) => ({
      clicked: prevState.clicked === id ? null : id, // <-- toggle back to null or to new id
    }));

  };

  let fetchMachines = (url, id, points) => {
    var params = { id: id, points: points };
    axios.put(url).then((response) => response);
    //axios.put(url, { id: id, points: points }).then((response) => response);
  };


  let handleBlur = (e, id, perf, points, depId, dateOfEntry) => {
    var url = SGD_API_HOST;
    var coefficient = conf.index.substr(0, 3);
    switch (coefficient) {
      case "mp-":
        url +=
          "/api/Performance/update-morning-points?id=" +
          perf +
          "&points=" +
          points;
        break;
      case "ml-":
        url +=
          "/api/Performance/update-morning-loadings?id=" +
          perf +
          "&loadings=" +
          points;
        break;
        case "mh-":
          url +=
            "/api/Performance/update-morning-hours-per-shift?hours-per-shift=" +
            points +
            "&id=" +
            perf;
          break;
          case "ms-":
            url +=
              "/api/HealthAndSafety/update-hsw-by-id?id=" +
              perf +
              "&points=" +
              points +
              "&departmentId=" +
              depId +
              "&dateOfEntry=" +
              dateOfEntry +
              "&shift=" +
              coefficient;
            break;
      case "ep-":
        url +=
          "/api/Performance/update-evening-points?id=" +
          perf +
          "&points=" +
          points;
        break;
      case "el-":
        url +=
          "/api/Performance/update-evening-loadings?id=" +
          perf +
          "&loadings=" +
          points;
        break;
        case "eh-":
          url +=
            "/api/Performance/update-evening-hours-per-shift?id=" +
            perf +
            "&hours-per-shift=" +
            points;
          break;
          case "es-":
            url +=
              "/api/HealthAndSafety/update-hsw-by-id?id=" +
              perf +
              "&points=" +
              points +
              "&departmentId=" +
              depId +
              "&dateOfEntry=" +
              dateOfEntry +
              "&shift=" +
              coefficient;
            break;
      default:
        break;
    }
    fetchMachines(url, perf, points);
    conf.setInputId({ clicked: -1 });
  };
  let handleval = (value) => {
    if (typeof value !== "number") {
      return 0;
    }
    return value;
  };
  return (

  <td
    key={conf.index}
    onDoubleClick={(event) => handleClick(event, conf.index, conf.perfId, conf.departmentId, conf.dateOfEntry)}
  >
    {conf.clicked === conf.index ? (
      <input
        className={"table-input"}
        type="text"
        defaultValue={handleval(conf.value)}
        onBlur={(event) =>
          handleBlur(
            event,
            conf.index,
            conf.perfId,
            event.currentTarget.value,
            conf.departmentId,
            conf.dateOfEntry
          )
        }
        autoFocus
      />
    ) : (
      <span className={"input-like"}>{conf.value}</span>
    )}
  </td>
  
  );
}

export default InputMarker;
