import React, { useEffect, useState } from "react";
import InputMarker from "../Helper/InputMarker";
import {
  columns,
  columnsStd,
  columnsDf,
  columnsOem,
  columnsLIN,
  small,
  smallLIN,
  columnsTh3,
  columnsTh1,
  columnsTh2,
  columnsTh4,
} from "../Helper/Columns";
import { usePerformanceContext } from "../../Context/PerformanceProvider";

const Reacttable = ({ departmentId, position, resolution }) => {
  let PerformanceContext = usePerformanceContext();
  let [result, setResult] = useState([]);
  let [inputId, setInputId] = useState({ clicked: null });

  useEffect(() => {
    if (
      PerformanceContext.propData !== undefined &&
      PerformanceContext.propData !== null
    ) {
      setResult(PerformanceContext.propData);
    }
  }, [PerformanceContext.propData]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function checkZero(value) {
    if (value === null) {
      return (value = "-");
    }
    return value;
  }

  function checkPercentagePerformance(point)
  {
    if ((point.PerfDaily === 0 || point.PerfDaily === null) && point.Loading > 0)
      return 0 + '%';
    return checkPercentage(point.PerfDaily)
  }

  function checkPercentage(value) {
    if (value === null) {
      return (value = "-");
    }
    return `${parseFloat(value).toFixed(1)}%`;
  }
  const mapped = Object.values(result);
  return (
    <div
      className={"point-container " + resolution}
      style={{
        paddingBottom: position,
      }}
    >
      <table className={"pointsTable " + resolution}>
        <thead>
          <tr className="shiftTitle">
            {(() => {
              if (windowSize.innerWidth > 1200) {
                if (departmentId * 1.0 === 8) {
                  return columnsTh1.map((head, index) => (
                    <th key={index}>{head}</th>
                  ));
                } else if (departmentId * 1.0 === 2) {
                  return columnsTh2.map((head, index) => (
                    <th key={index}>{head}</th>
                  ));
                } else if (departmentId * 1.0 === 0) {
                  return columnsTh4.map((head, index) => (
                    <th key={index}>{head}</th>
                  ));
                } else {
                  return columnsTh3.map((head, index) => (
                    <th key={index}>{head}</th>
                  ));
                }
              }
            })()}
          </tr>
          <tr className="tableName">
            {(() => {
              if (windowSize.innerWidth < 1200) {
                if (departmentId * 1.0 === 0) {
                  return smallLIN.map((head, index) => <th key={index}>{head}</th>);
                }
                else{
                  return small.map((head, index) => <th key={index}>{head}</th>);
                }
              } else {
                if (departmentId * 1.0 === 8) {
                  return columnsStd.map((head, index) => (
                    <th key={index}>{head}</th>
                  ));
                } else if (departmentId * 1.0 === 2) {
                  return columnsDf.map((head, index) => (
                    <th key={index}>{head}</th>
                  ));
                } else if (departmentId * 1.0 === 6) {
                  return columnsOem.map((head, index) => (
                    <th key={index}>{head}</th>
                  ));
                } else if (departmentId * 1.0 === 0) {
                  return columnsLIN.map((head, index) => (
                    <th key={index}>{head}</th>
                  ));
                } else {
                  return columns.map((head, index) => (
                    <th key={index}>{head}</th>
                  ));
                }
              }
            })()}
          </tr>
        </thead>
        <tbody>
          {mapped.map((point, index) => (
            <tr key={index}>
              <td>
                {point.Date.substr(
                  point.Date.indexOf("-") + 1,
                  point.Date.indexOf("T") - 5
                ).replace("-", "/")}
              </td>
              <td>{checkZero(point.Shift)}</td>
              <td>{checkZero(point.Points)}</td>
              <td>{checkZero(point.Loading)}</td>
              <td>{checkPercentagePerformance(point)}</td>
              {departmentId * 1.0 === 0 && (
                <td>{checkPercentage(point.Last6WeeksPerf)}</td>
              )}
              <td>{checkPercentage(point.PerfWeekly)}</td>
              {departmentId * 1.0 !== 0 && (
                <td>{checkPercentage(point.Last5DaysPerfPercent)}</td>
              )}

              {windowSize.innerWidth > 1200 && (
                <>
                  <InputMarker
                    clicked={inputId.clicked}
                    index={"mp-" + index}
                    value={checkZero(point.MorningPoints)}
                    setInputId={setInputId}
                    perfId={point.Id}
                    departmentId={point.DepartmentId}
                    dateOfEntry={point.Date}
                  />
                  <InputMarker
                    clicked={inputId.clicked}
                    index={"ml-" + index}
                    value={checkZero(point.MorningLoading)}
                    setInputId={setInputId}
                    handleBlur={() => setInputId({ clicked: -1 })}
                    perfId={point.Id}
                    departmentId={point.departmentId}
                    dateOfEntry={point.Date}
                  />
                  <td>{checkPercentage(point.MorningPerfPercentDaily)}</td>
                  <td>{checkPercentage(point.MorningPerfPercentWeekly)}</td>
                  <InputMarker
                    clicked={inputId.clicked}
                    index={"mh-" + index}
                    value={checkZero(point.MorningHoursPerShift)}
                    setInputId={setInputId}
                    handleBlur={() => setInputId({ clicked: -1 })}
                    perfId={point.Id}
                    departmentId={point.DepartmentId}
                    dateOfEntry={point.Date}
                  />
                  <InputMarker
                    clicked={inputId.clicked}
                    index={"ms-" + index}
                    value={checkZero(point.HSWMorning)}
                    setInputId={setInputId}
                    handleBlur={() => setInputId({ clicked: -1 })}
                    perfId={point.HSWMorningId}
                    departmentId={point.DepartmentId}
                    dateOfEntry={point.Date}
                  />

                  <InputMarker
                    clicked={inputId.clicked}
                    index={"ep-" + index}
                    value={checkZero(point.EveningPoints)}
                    setInputId={setInputId}
                    handleBlur={() => setInputId({ clicked: -1 })}
                    perfId={point.Id}
                    departmentId={point.DepartmentId}
                    dateOfEntry={point.Date}
                  />
                  <InputMarker
                    clicked={inputId.clicked}
                    index={"el-" + index}
                    value={checkZero(point.EveningLoading)}
                    setInputId={setInputId}
                    handleBlur={() => setInputId({ clicked: -1 })}
                    perfId={point.Id}
                    departmentId={point.DepartmentId}
                    dateOfEntry={point.Date}
                  />
                  <td>{checkPercentage(point.EveningPerfPercentDaily)}</td>
                  <td>{checkPercentage(point.EveningPerfPercentWeekly)}</td>
                  <InputMarker
                    clicked={inputId.clicked}
                    index={"eh-" + index}
                    value={checkZero(point.EveningHoursPerShift)}
                    setInputId={setInputId}
                    handleBlur={() => setInputId({ clicked: -1 })}
                    perfId={point.Id}
                    departmentId={point.DepartmentId}
                    dateOfEntry={point.Date}
                  />
                  <InputMarker
                    clicked={inputId.clicked}
                    index={"es-" + index}
                    value={checkZero(point.HSWEvening)}
                    setInputId={setInputId}
                    handleBlur={() => setInputId({ clicked: -1 })}
                    perfId={point.HSWEveningId}
                    departmentId={point.DepartmentId}
                    dateOfEntry={point.Date}
                  />
                </>
              )}
              {departmentId * 1.0 === 8 && windowSize.innerWidth > 1200 ? (
                <>
                  <td>{checkZero(point.ScannedGrillsQty)}</td>
                  <td>{checkZero(point.Setup)}</td>
                </>
              ) : null}
              {departmentId * 1.0 === 2 && windowSize.innerWidth > 1200 ? (
                <td>{checkZero(point.DfQty)}</td>
              ) : null}
              
              {departmentId * 1.0 === 0 && (
                <td>{checkPercentage(point.Last5DaysPerfPercent)}</td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default Reacttable;
