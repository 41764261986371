import styled from "styled-components";

const SummaryTitle = styled.p`
    padding: 0px 15px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    text-align: center;
    color: #324395;

    @media (max-width: 1230px) and (min-width: 992px) {
      font-size: 35px;
    }
`;

const SummaryRow = styled.div`
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  text-align: center;
  color: #324395;
  border-radius: 15px;

  @media (max-width: 1230px) and (min-width: 992px) {
    font-size: 30px;
  }
`;

const SummaryText = styled.span``

const SummaryNumber = styled.span`
  font-weight: 600;
  display: flex;
  align-items: center;
`;

const SummaryCircle = styled.span`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display:block;
  margin-left: 7px;
  border: 3px solid black;
`


export { SummaryTitle, SummaryRow, SummaryText, SummaryNumber, SummaryCircle};
