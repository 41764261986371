import React from "react";
import { RunningQuarterValuesContainer, Title, Row, Number } from "./styles"; 
import { useRunningQuarterValuesContext } from "../../Context/runningQuarterValuesContext";

function RunningQuarterValuesComp(props) {
    
    let RunningQuarterContext = useRunningQuarterValuesContext();
    let NetSales = RunningQuarterContext.propData.NetSales < 1000
        ? String.prototype.concat("$", (+RunningQuarterContext.propData.NetSales).toFixed(0), "K")
        : String.prototype.concat("$",(RunningQuarterContext.propData.NetSales / 1000).toFixed(3),"M");
    let Labor = RunningQuarterContext.propData.Labor < 1000
        ? String.prototype.concat("$", (+RunningQuarterContext.propData.Labor).toFixed(0), "K")
        : String.prototype.concat("$",(RunningQuarterContext.propData.Labor / 1000).toFixed(3),"M");

    return (
      <RunningQuarterValuesContainer>
        <Title>Running Quarter Values</Title>
        <Row>Net/Gross Ratio
          <Number>{(RunningQuarterContext.propData.Coefficient * 100).toFixed(2).toString()}%</Number>
        </Row>
        <Row>Net Sales 
          <Number>{NetSales}</Number>
        </Row>
        <Row>Labor 
          <Number>{Labor}</Number>
        </Row>
        <Row>Labor/Net Sales Ratio 
          <Number>{(+RunningQuarterContext.propData.Ratio * 100).toFixed(2).toString()}%</Number>
        </Row>
      </RunningQuarterValuesContainer>
  );
}

export { RunningQuarterValuesComp };
