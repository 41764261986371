import ReactApexChart from "react-apexcharts";
import React, { useEffect, useState, memo } from "react";
import { useLineGraphContext } from "../../Context/lineGraphContext";
import dayjs from "dayjs";

function LineGraphComp({ height, position, showFullDate, notShowYaxis }) {

  let LineGraphContext = useLineGraphContext();
  
  const seriesFunc = (param) => {
    
    let dataArray = []
    LineGraphContext.propData.Values?.forEach(x => {
        dataArray.push({
            name: x.Label,
            data: x.Values,
        })
    })
    
    return dataArray;
  };

  const optFunc = (param) => {
    let options = {
      colors: ["#3347E6", "#EF5DA8"],
      grid: {
        row: {
          opacity: 0.5,
        },
        column: {
          opacity: 0.5,
        },
      },
      legend: {
        position: "top",
      },
      yaxis: {
        show: !notShowYaxis,
        min: 0,
        decimalsInFloat: 2,
        forceNiceScale: true,
        labels: {
          style: {
            color: "#008FFB",
          },
        },
      },

      chart: {
        toolbar: {
          show: false,
        },
      },
      markers: {
        size: 3,
        hover: {
          sizeOffset: 6,
        },
      },
      stroke: {
        curve: "smooth",
        width: [2, 2],
        dashArray: [2, 0],
      },
      xaxis: {
        type: "string",
        categories: ["01/06", "02/06", "03/06", "04/06"],
        labels: {
            formatter: function (val) {
              return showFullDate ? dayjs(val).format("YYYY/MM/DD") : val;
            },
          },
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };

    let def = () => options;
    if (LineGraphContext.propData == undefined || LineGraphContext.propData == null) {
      return def();
    }
    if (Array.isArray(LineGraphContext.propData.ValuesDate) && LineGraphContext.propData.ValuesDate.length > 0) {
      let opt_dat = [];

      for (const item of LineGraphContext.propData.ValuesDate) {
        opt_dat.push(
          showFullDate ? item :
          item.substr(
            item.indexOf("-") + 1,
            item.indexOf("T") - 5
          ).replace("-", "/")
        );
      }

      options.xaxis.categories = opt_dat;
      return def();
    }
    return def();
  };

  if (window.matchMedia("(max-width: 600px)").matches && showFullDate) // work only after page refresh
    height = height / 2;

  return (
    <div
      style={{
        textAlign: "center",
        paddingBottom: position,
      }}
    >
      <ReactApexChart
        options={optFunc()}
        series={seriesFunc()}
        type="line"
        height={height}
      />
    </div>
  );
}

export default LineGraphComp;
