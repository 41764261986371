import React from "react";
import { Title, Row, Number, Value, Desc, SubValue, SubDesc } from "./styles"; 

function BacklogComp(props) {
    
    const {data} = props
    let backlogCurrent =
    data?.BacklogCurrent < 1000
      ? String.prototype.concat("$", data?.BacklogCurrent.toFixed(0), "K")
      : String.prototype.concat("$",(data?.BacklogCurrent / 1000).toFixed(3),"M");

    return (
      <div>
        <Value>{backlogCurrent}</Value>
        <Desc>Actual backlog</Desc>
        <SubValue>{data?.WeeksToZeroBacklogMonthlyProjection ? Math.round(data?.WeeksToZeroBacklogMonthlyProjection).toString() : "N/A"}</SubValue>
        <SubDesc>Weeks to zero backlog</SubDesc>
      </div>
  );
}

export { BacklogComp };