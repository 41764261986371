import React from "react";
import { SummaryTitle, SummaryRow, SummaryText, SummaryNumber, SummaryCircle } from "./styles";
import { useSummaryContext } from "../../Context/lateOrderSummaryContext";

function LateOrderSummary() {
  let SummaryContext = useSummaryContext();

  const getAvgDaysLateStyles = (AverageDayLate) => {
    
    var backgroundColor
    switch (true) {
      case +AverageDayLate >= 3:
        backgroundColor = "#ff0000" // Red
        break
      case +AverageDayLate >= 2:
        backgroundColor = "#f58002" // Orange
        break
      case +AverageDayLate >= 1:
        backgroundColor = "#f5e102" // Yellow
        break
      default:
        backgroundColor = "#00c918" // Green
        break
    }

    var color
    var textShadow = ""
    switch (true) {
      case +AverageDayLate >= 1 && +AverageDayLate < 2:
        color = "#324395"
        break
      default:
        color = "white"
        textShadow = "-1.5px -1.5px 0px #324395, 1.5px 1.5px 0px #324395, 1.5px -1.5px 0px #324395, -1.5px 1.5px 0px #324395"
        break
    }
    
    return { backgroundColor: backgroundColor, 
      color: color,
      boxShadow: "2px 2px 5px "+ backgroundColor,
      textShadow: textShadow }
  }

  const getLateOrdersPercentageStyles = (PercentRate) => {
    
    PercentRate = PercentRate.slice(0, -1)

    var backgroundColor
    switch (true) {
      case +PercentRate >= 50:
        backgroundColor = "#ff0000" // Red
        break
      case +PercentRate >= 25:
        backgroundColor = "#f58002" // Orange
        break
      case +PercentRate >= 10:
        backgroundColor = "#f5e102" // Yellow
        break
      default:
        backgroundColor = "#00c918" // Green
        break
    }

    var color
    var textShadow = ""
    switch (true) {
      case +PercentRate >= 10 && +PercentRate < 25:
        color = "#324395"
        break
      default:
        color = "white"
        textShadow = "-1.5px -1.5px 0px #324395, 1.5px 1.5px 0px #324395, 1.5px -1.5px 0px #324395, -1.5px 1.5px 0px #324395"
        break
    }
    
    return { backgroundColor: backgroundColor, 
      color: color,
      boxShadow: "2px 2px 5px "+ backgroundColor,
      textShadow: textShadow }
  }

  return (
    <div>
      <SummaryTitle>Late Order Summary</SummaryTitle>
      <SummaryRow style={getAvgDaysLateStyles(SummaryContext.propData.AverageDayLate)}>
        <SummaryText> Average Day Late </SummaryText>
        <SummaryNumber>
          {SummaryContext.propData.AverageDayLate}
        </SummaryNumber>
      </SummaryRow>
      <SummaryRow>
        <SummaryText>Total Late Orders</SummaryText>
        <SummaryNumber>
          {SummaryContext.propData.TotalLateOrders}
        </SummaryNumber>
      </SummaryRow>
      <SummaryRow>
        <SummaryText>Total Orders</SummaryText>
        <SummaryNumber>
          {SummaryContext.propData.TotalOrders}
        </SummaryNumber>
      </SummaryRow>
      <SummaryRow style={getLateOrdersPercentageStyles(SummaryContext.propData.PercentRate)}>
        <SummaryText> Percent Late </SummaryText>
        <SummaryNumber>
          {SummaryContext.propData.PercentRate}
        </SummaryNumber>
      </SummaryRow>
    </div>
  );
}

export { LateOrderSummary };
