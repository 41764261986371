import React, { useEffect, useState } from "react";
import useSWR from 'swr'
import axios from 'axios'
import {useAuth} from 'react-oidc-context'
import {SGD_API_HOST} from '../env'


interface SummaryData {
    AverageDayLate: String,
    TotalLateOrders: String,
    TotalOrders: String,
    PercentRate: String,
  }
  
  //default object TODO default
  const DataItem = {
    AverageDayLate: "0",
    TotalLateOrders: "0",
    TotalOrders: "0",
    PercentRate: "0%",
  }

  const Data:SummaryData = {
    AverageDayLate: DataItem.AverageDayLate,
    TotalLateOrders: DataItem.TotalLateOrders,
    TotalOrders: DataItem.TotalOrders,
    PercentRate: DataItem.PercentRate,
  }

const SummaryContext = React.createContext({
    propData: {...Data}
  });

  function SummaryProvider({ children, Date }){
    let [fetched, setFetched] = useState({...Data});//initialize state
    let auth=useAuth()
  
    // check to take the newest data or for previous weeks
    var rowDate
    if(Date == 0)
      rowDate = "";
    else 
      rowDate = "/" + Date;

    const fetcher = url => axios.request({
      url:url,
      method: 'get',
      baseURL:SGD_API_HOST,
      headers: {
        Authorization: `bearer ${auth.user?.access_token}`},
      params:{},
      //data:{},
      responseType:'json',
    }).then(res => res.data)
    const { data } = useSWR('/api/LateOrderReport/GetLateOrderSummary' + rowDate, fetcher, { refreshInterval: 10000 })//fetch data every 5 seconds
    
    useEffect(()=>{
      //if data has changed, then update state
      if(data !== undefined){
        setFetched(data)
      }
      
    },[data])
    return (
      <SummaryContext.Provider 
        value={{
          propData: fetched //pass property from state
        }}>
        {children}
      </SummaryContext.Provider>
    );
  }
  
  function useSummaryContext() {
    const context = React.useContext(SummaryContext);
    if (context === undefined) {
      throw new Error("Error...");
    }
    return context;
  }
  
  export {SummaryProvider, useSummaryContext, SummaryContext, SummaryData}