import React from "react";
import Governance from "./DashboardGov/GovernanceDash";
import Settings from "./DashboardGov/Settings";
import Production from "./DashboardGov/Production";
import Scheduling from "./DashboardGov/Scheduling";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from 'oidc-client-ts';
import { AIS_HOST, SGD_HOST } from "./env";
import { BrowserRouter, Routes as Switch, Route, Navigate } from 'react-router-dom';
import Routes from './Components/ComponentsData/Routes';
import { useAuth } from "react-oidc-context";
import Chart from "./DashboardGov/Chart";

export const oidcConfig = {
  client_id: 'gov_dash',
  redirect_uri: `${SGD_HOST}${Routes.AuthenticationCallback}`,
  scope:
    'openid profile Manufacturing.API Document.API SalesGovernance.API',
  authority: AIS_HOST,
  silent_redirect_uri: `${SGD_HOST}${Routes.SilentAuthenticationCallback}`,
  post_logout_redirect_uri: `${AIS_HOST}/Home/Logout`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: false,//dev
  checkSessionInterval: 500,
  client_secret: "gov_dash_ddhxnDBs_UwjXTU29",
  stateStore: new WebStorageStateStore({ store: window.localStorage })
};

function App() {

  return (
    <AuthProvider {...oidcConfig}>
      <BrowserRouter>
        <Navigation />
      </BrowserRouter>
    </AuthProvider>
  );
}

function Navigation() {

  let auth = useAuth();
  const role = auth.user?.profile?.role;

// Define the common routes for all roles
const commonRoutes = (
  <>
    <Route path={Routes.AuthenticationCallback} element={<Governance />} />
    <Route path={Routes.SilentAuthenticationCallback} element={<Governance />} />
    <Route path="/governance" element={<Governance />} />
    <Route path="/authentication/*" />
    <Route path="*" element={<Navigate to="/governance" />} />
  </>
);

  if (role === "Production") {
    return (
      <Switch>
        <Route path={Routes.AuthenticationCallback} element={<Production />} />
        <Route path={Routes.SilentAuthenticationCallback} element={<Production />} />
        <Route path="/production" element={<Production />} />
        <Route path="/authentication/*" />
        <Route path="*" element={<Navigate to="/production" />} />
      </Switch>
    );
  } else if (role === "Mechanic") {
    return (
      <Switch>
        {commonRoutes}
      </Switch>
    );
  } else if (role && role.includes("Mechanic") && role.includes("Production")) {
    return (
      <Switch>
        {commonRoutes}
        <Route path="/production" element={<Production />} />
      </Switch>
    );
  } else {
    return (
      <Switch>
        {commonRoutes}
        <Route path="/payroll" element={<Settings />} />
        <Route path="/production" element={<Production />} />
        <Route path="/production/product-delay" element={<Scheduling />} />
        <Route path="/governance/chart" element={<Chart />} />
      </Switch>
    )
  }
}

export default App;
