import React, { useEffect, useState } from "react";
import useSWR from 'swr'
import axios from 'axios'
import {useAuth} from 'react-oidc-context'
import {SGD_API_HOST} from '../env'


interface RunningQuarterData {
    Coefficient: String,
    NetSales: String,
    Labor: String,
    Ratio: String,
  }
  
  //default object TODO default
  const DataItem = {
    Coefficient: "0%",
    NetSales: "0",
    Labor: "0",
    Ratio: "0%",
  }

  const Data:RunningQuarterData = {
    Coefficient: DataItem.Coefficient,
    NetSales: DataItem.NetSales,
    Labor: DataItem.Labor,
    Ratio: DataItem.Ratio,
  }

const RunningQuarterContext = React.createContext({
    propData: {...Data}
  });

  function RunningQuarterProvider({ children, Week }){
    let [fetched, setFetched] = useState({...Data});//initialize state
    let auth=useAuth()
    
    if(!Week) Week = 0

    const fetcher = url => axios.request({
      url:url,
      method: 'get',
      baseURL:SGD_API_HOST,
      headers: {
        Authorization: `bearer ${auth.user?.access_token}`},
      params:{},
      //data:{},
      responseType:'json',
    }).then(res => res.data)
    const { data } = useSWR('/api/GovernanceWeeklyStatistics/getRunningQuarterValues/' + Week, fetcher, { refreshInterval: 10000 })//fetch data every 5 seconds
    
    useEffect(()=>{
      //if data has changed, then update state
      if(data !== undefined){
        setFetched(data)
      }
      
    },[data])
    return (
      <RunningQuarterContext.Provider 
        value={{
          propData: fetched //pass property from state
        }}>
        {children}
      </RunningQuarterContext.Provider>
    );
  }
  
  function useRunningQuarterValuesContext() {
    const context = React.useContext(RunningQuarterContext);
    if (context === undefined) {
      throw new Error("Error...");
    }
    return context;
  }
  
  export {RunningQuarterProvider, useRunningQuarterValuesContext, RunningQuarterContext, RunningQuarterData}