import React, { useEffect, useState } from "react";
import useSWR from 'swr'
import axios from 'axios'
import {useAuth} from 'react-oidc-context'
import {SGD_API_HOST} from '../env'


interface LineGraphData {
    Date: Date,
    Value: Number,
  }
  
  //default object TODO default
  const DataItem = {
    Date: new Date(),
    Value: 0,
  }

  const Data:LineGraphData = {
    Date: DataItem.Date,
    Value: DataItem.Value,
  }

const LineGraphContext = React.createContext({
    propData: {...Data}
  });

  function LineGraphProvider({ children, url, startDate, endDate }){
    let [fetched, setFetched] = useState({...Data});//initialize state
    let auth=useAuth()

    const fetcher = url => axios.request({
      url:url,
      method: 'get',
      baseURL:SGD_API_HOST,
      headers: {
        Authorization: `bearer ${auth.user?.access_token}`},
      params:{},
      responseType:'json',
    }).then(res => res.data)
    const { data } = useSWR(`/api/GovernanceWeeklyStatistics/${url}/${startDate.toISOString()}/${endDate.toISOString()}/`, fetcher, { refreshInterval: 10000 })//fetch data every 5 seconds

    useEffect(()=>{
      //if data has changed, then update state
      if(data !== undefined){
        setFetched(data)
      }
      
    },[data])
    return (
      <LineGraphContext.Provider 
        value={{
          propData: fetched //pass property from state
        }}>
        {children}
      </LineGraphContext.Provider>
    );
  }
  
  function useLineGraphContext() {
    const context = React.useContext(LineGraphContext);
    if (context === undefined) {
      throw new Error("Error...");
    }
    return context;
  }
  
  export {LineGraphProvider, useLineGraphContext, LineGraphContext, LineGraphData}