import React from "react";
import { SidebarData } from "./ComponentsData/SidebarData";
import AccountIcon from "../assets/images/UserIcon.svg";
// import Logo from "../assets/images/LOGO.png";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "react-oidc-context";

function Sidebar() {
  let navigate = useNavigate();
  let auth = useAuth();
  //auth.currentUser.getIdTokenResult();
  return (
    <div className="navPanel">
      <div className="navFixing">
        <div className="navUser">
          <img src={AccountIcon} alt="account" />
          <p>{auth.isAuthenticated && String.prototype.concat("  Hi, ", auth.user?.profile?.FirstName, "!")}</p>
        </div>
        <ul className="navPanelList">
          {SidebarData.map((val, key) => {
            if (val.users.filter(x => auth.user?.profile?.role.includes(x)).length <= 0) return // access control

            let isActive = window.location.pathname === val.link
            val.subSections?.forEach((e) => { if (e.link === window.location.pathname) isActive = true })
            return (
              <li
                key={key}
                className="navTabs"
                id={isActive ? "active" : ""} //Return, when need show Tabs
              //id={val.id}
              >
                <div className="nav__mainSection">
                  <div className="navFlag"></div>
                  <div className="navIcons" id={window.location.pathname === val.link ? "active-icon" : ""} onClick={() => { navigate(val.link) }}>{val.icon}</div>{" "}
                  <div className="navTitle" id={window.location.pathname === val.link ? "active-section" : ""} onClick={() => { navigate(val.link) }}>{val.title}</div>
                </div>
                {val.subSections ? val.subSections.map((subVal, subKey) => {
                  if (subVal.users.filter(x => auth.user?.profile?.role.includes(x)).length <= 0) return // access control

                  return (
                    <>
                      <div className="navFlag"></div>
                      <div className="navIcons__subItem" id={window.location.pathname === subVal.link ? "active-icon" : ""} onClick={() => { navigate(subVal.link) }}>{subVal.icon}</div>{" "}
                      <div className="nav__subSections" id={window.location.pathname === subVal.link ? "active-section" : ""} key={subKey}
                        onClick={() => { navigate(subVal.link) }}>
                        <p>{subVal.title}</p>
                      </div>
                    </>
                  )
                }) : ''}
              </li>
            );
          })}
        </ul>
        <div className="LOGO" />
      </div>
    </div>
  );
}

export default Sidebar;
