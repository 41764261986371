import React, { useEffect, useState } from "react";
import Sidebar from "../Components/SideBar";
import Windows, {WindowsBase} from "../Components/Windows/index";
import { useAuth } from "react-oidc-context";
import { SalesGovernanceProvider, useSalesGovernanceContext } from "../Context/salesGovernanceContext";
import axios from 'axios'
import {SGD_API_HOST} from '../env'

function wrapContent(auth, content, list) {
  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    return <React.Fragment>{content(list)}</React.Fragment>;
  }
  auth.signinRedirect()
  //return <button onClick={() => void auth.signinRedirect()}>Log in</button>;
}

function content(list) {

  return (
    <React.Fragment>
      <div className="staticElement">
        <Sidebar />
      </div>
      <div className="window">
        <SalesGovernanceProvider>
          <Windows/>
        </SalesGovernanceProvider>
        <div id="scroll">
          {list && list.map((item,i)=>(
            <WindowsBase wData={item} key={i} week={i} CurrentDate={item.CurrentWeekFriday}></WindowsBase>
          ))}
      </div>
      </div>
    </React.Fragment>
  );
}

function Governance() {

  const auth = useAuth();

  /* Scroll handle */
  //const {getPage} = useSalesGovernanceContext();
  const [list,setList] = useState([]);
  const [page, setPage] = useState(1)
  const [isDone, setDone] = useState(false)

  useEffect(() =>{
    
    if(isDone){
      const url = auth.user?.profile?.role.includes("Mechanic") && !auth.user?.profile?.role.includes("Admin") 
      ? '/api/GovernanceWeeklyStatistics/getMechanicsGovernanceStatistics/'
      : '/api/GovernanceWeeklyStatistics/getGovernanceStatistics/'

      axios.get(SGD_API_HOST + url + page, {
        headers: {
            Authorization: `bearer ${auth.user?.access_token}`},
      }).then(res => {
        setList(list=>[...list, res.data])
        setPage((p) => p + 1)
      })
      .finally(() => setDone(false))
    }
  }, [isDone])

  const scrollHandler = (e) => {
    if(e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100){
      setDone(true)
    }
  }

  useEffect( () => {
    document.addEventListener('scroll', scrollHandler)
    return function(){
      document.removeEventListener('scroll', scrollHandler)
    }
  })
  /* End of scroll handle */
  
  return (
    <div className="parentDiv">
      {wrapContent(auth, content, list)}
    </div>
  );
}

export default Governance;
