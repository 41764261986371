import React, { useEffect, useState } from "react";
import useSWR from 'swr'
import axios from 'axios'
import {useAuth} from 'react-oidc-context'
import {SGD_API_HOST} from '../env'

//SalesGovernanceData, ManufacturingData
interface ManufacturingData {
    PostedDate: Date,
    LeadTimes: Array<ManufacturingDataItem>,
  }

  interface ManufacturingDataItem {
    Id: string,
    EntryDate: Date,
    Department: string,
    LeadTime: number
  }
  
  //default object TODO default
  const DataItem = {
    Id: "",
    EntryDate: new Date("2022-04-18T00:00:00"),
    Department: "STD",
    LeadTime: 5
  }

  const Data:ManufacturingData = {
    PostedDate: new Date(),
    LeadTimes:new Array<ManufacturingDataItem>(DataItem)
  }

const ManufacturingContext = React.createContext({
    propData: {...Data}
  });

  function ManufacturingProvider({ children, Date }){
    let [fetched, setFetched] = useState({...Data});//initialize state
    let auth=useAuth()
  
    // check to take the newest data or for previous weeks
    var rowDate
    if(Date == 0)
      rowDate = "";
    else 
      rowDate = "/" + Date;

    const fetcher = url => axios.request({
      url:url,
      method: 'get',
      baseURL:SGD_API_HOST,
      headers: {
        Authorization: `bearer ${auth.user?.access_token}`},
      params:{},
      //data:{},
      responseType:'json',
    }).then(res => res.data)
    const { data } = useSWR('/get-leadtimes' + rowDate, fetcher, { refreshInterval: 10000 })//fetch data every 5 seconds
    
    useEffect(()=>{
      //if data has changed, then update state
      if(data !== undefined){
        setFetched(data[0])
      }
      
    },[data])
    return (
      <ManufacturingContext.Provider 
        value={{
          propData: fetched //pass property from state
        }}>
        {children}
      </ManufacturingContext.Provider>
    );
  }
  
  function useManufacturingContext() {
    const context = React.useContext(ManufacturingContext);
    if (context === undefined) {
      throw new Error("Error...");
    }
    return context;
  }
  
  export {ManufacturingProvider, useManufacturingContext, ManufacturingContext, ManufacturingData}