import React, { useEffect, useState } from "react"
import { useAuth } from "react-oidc-context"
import axios from 'axios'
import { SGD_API_HOST } from '../../env'
import {
    InputsWrap,
    LargeWindowTitle,
} from "./styles";
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import DoneIcon from '@mui/icons-material/Done';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function SchedulingWindow() {

    const [delays, setDelays] = useState([])
    const [loading, setLoading] = useState(true)
    //const [services, setRushServices] = useSatate([])

    const [reasons, setReasons] = useState([])
    const [loadReasons, setLoadReasons] = useState(true)

    const [description, setDescription] = useState("")
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(null)
    const [reason, setReason] = useState("")

    const auth = useAuth();

    // GET request to get add dates
    useEffect(() => {
        if (loading) {
            axios.get(SGD_API_HOST + '/get-production-issues', {
                headers: {
                    Authorization: `bearer ${auth.user?.access_token}`
                },
            }).then((response) => {
                setDelays(response.data.sort().reverse())
                setLoading(false)
            })
        }
    }, [loading, auth.user?.access_token])

    useEffect(() => {
        if (loadReasons) {
            axios.get(SGD_API_HOST + '/get-production-issues-reasons', {
                headers: {
                    Authorization: `bearer ${auth.user?.access_token}`
                },
            }).then((response) => {
                setReasons(response.data.sort())
                setLoadReasons(false)
            })
        }
    }, [loadReasons, auth.user?.access_token])

    const changeDescHandler = (e, i) => {
        let newDelays = [...delays]
        newDelays[i].Description = e
        setDelays(newDelays)

        setTimeout(sendNewDescHandler, 1000, e, i);
    }

    const sendNewDescHandler = (e, i) => {
        if (delays[i].Description !== e)
            return

        let data = {
            id: delays[i].Id,
            description: delays[i].Description,
            endDate: delays[i].EndDate,
        }

        fetch(SGD_API_HOST + '/update-production-issues', {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth.user?.access_token}`,
            },
            body: JSON.stringify(data)
        }).then((response) => { })
    }

    const changeDateHandler = (e, i) => {
        let newDelays = [...delays]
        newDelays[i].EndDate = e
        setDelays(newDelays)

        let data = {
            id: delays[i].Id,
            description: delays[i].Description,
            endDate: delays[i].EndDate,
        }

        fetch(SGD_API_HOST + '/update-production-issues', {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth.user?.access_token}`,
            },
            body: JSON.stringify(data)
        }).then((response) => { })
    }

    const resolveHandler = (e, i) => {

        let data = delays[i].Id

        fetch(SGD_API_HOST + '/resolve-production-issues', {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth.user?.access_token}`,
            },
            body: JSON.stringify(data)
        }).then((response) => { setLoading(true) })
    }

    const closeAddWindowHandler = (e) => {
        let background = document.querySelector(".scheduling__add-new-background")
        if (e.target === background)
            background.classList.add("hidden")
    }

    const addDescriptionHandler = (e) => {
        if (e.target.value.length > 64) {
            alert("Maximum description length - 64 characters")
            e.target.value = e.target.value.slice(0, -1);
            e.target.focus();
            e.target.setSelectionRange(e.target.value.length, e.target.value.length);
        }
        setDescription(e.target.value)
        if (description)
            document.querySelector('#schelduling__new-description').classList.remove("wrong")
    }

    const addStartDateHandler = (date) => {
        setStartDate(date)
        if (startDate)
            document.querySelector('#schelduling__new-startDate').classList.remove("wrong")
    }

    const addEndDateHandler = (date) => {
        setEndDate(date)
        if (endDate)
            document.querySelector('#schelduling__new-endDate').classList.remove("wrong")
    }

    const addReasonHandler = (e) => {
        if (e.target.value.length > 36) {
            alert("Maximum reason length - 36 characters")
            e.target.value = e.target.value.slice(0, -1);
            e.target.focus();
            e.target.setSelectionRange(e.target.value.length, e.target.value.length);
        }
        setReason(e.target.value)
    }

    const sendNewHandler = () => {

        if (!description)
            document.querySelector('#schelduling__new-description').classList.add("wrong")
        if (!startDate)
            document.querySelector('#schelduling__new-startDate').classList.add("wrong")
        if (!endDate)
            document.querySelector('#schelduling__new-endDate').classList.add("wrong")

        if (!description || !startDate || !endDate)
            return
        if (reason === null || reason.match(/^ *$/) !== null)
            setReason(undefined)

        let data = {
            description,
            startDate,
            endDate,
            reason,
        }

        fetch(SGD_API_HOST + '/add-production-issues', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth.user?.access_token}`,
            },
            body: JSON.stringify(data)
        }).then((response) => {
            document.querySelector(".scheduling__add-new-background").classList.add("hidden")
            setLoading(true)
            setLoadReasons(true)
            setDescription("")
            setStartDate(new Date())
            setEndDate(null)
            setReason("")
        })
    }

    function RushServicesTable() {
        const [checkboxes, setCheckboxes] = useState({});
        
        const handleCheckboxChange = (event) => {
            const { name, checked } = event.target;
            const checkboxNumber = parseInt(name.replace('checkbox', ''), 10);
    
            const updatedCheckboxes = { ...checkboxes };
    
            if (checked) {
                // If checked, set all checkboxes below to true
                for (let i = checkboxNumber; i <= 4; i++) {
                    updatedCheckboxes[`checkbox${i}`] = true;
                }
            } else {
                // If unchecked, set all checkboxes above to false
                for (let i = checkboxNumber; i >= 1; i--) {
                    updatedCheckboxes[`checkbox${i}`] = false;
                }
            }
    
            setCheckboxes(updatedCheckboxes);
    
            // Call the PUT method for the changed checkbox
            fetch(SGD_API_HOST + `/update-rush-service?rushIndex=${checkboxNumber}&availability=${checked}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${auth.user?.access_token}`,
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .catch(error => {
                    console.error('Error updating rush service:', error);
                });
        };
    
        const fetchRushServices = () => {
            fetch(SGD_API_HOST + '/get-rush-services', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${auth.user?.access_token}`,
                }
            })
                .then(response => response.json())
                .then(data => {
                    const updatedCheckboxes = {};
                    data.forEach(service => {
                        updatedCheckboxes[`checkbox${service.RushIndex}`] = service.IsActive;
                    });
                    setCheckboxes(updatedCheckboxes);
                })
                .catch(error => {
                    console.error('Error fetching rush services:', error);
                });
        };
    
        useEffect(() => {
            fetchRushServices();
        }, [auth.user?.access_token]);
    
        const getLabelClass = (checkboxName) => {
            return checkboxes[checkboxName] ? '' : 'strikethrough';
        };
    
        return (
            <div className="checkboxGroup">
                <div className="checkboxRow">
                    <input
                        className="checkboxRush"
                        type="checkbox"
                        name="checkbox1"
                        checked={checkboxes.checkbox1 || false} // Use a default value to avoid uncontrolled input warnings
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="checkbox1" className={getLabelClass('checkbox1')}>Rush SAME day: 10% extra</label>
                </div>
                <div className="checkboxRow">
                    <input
                        className="checkboxRush"
                        type="checkbox"
                        name="checkbox2"
                        checked={checkboxes.checkbox2 || false}
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="checkbox2" className={getLabelClass('checkbox2')}>Rush 2-day: 10% extra</label>
                </div>
                <div className="checkboxRow">
                    <input
                        className="checkboxRush"
                        type="checkbox"
                        name="checkbox3"
                        checked={checkboxes.checkbox3 || false}
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="checkbox3" className={getLabelClass('checkbox3')}>Rush 5-day: 10% extra</label>
                </div>
                <div className="checkboxRow">
                    <input
                        className="checkboxRush"
                        type="checkbox"
                        name="checkbox4"
                        checked={checkboxes.checkbox4 || false}
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="checkbox4" className={getLabelClass('checkbox4')}>Rush 10-day: 10% extra</label>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div id="inputsBlock">
                <div className="schedulingDataWindow">
                    <InputsWrap>
                        <LargeWindowTitle><CalendarIcon style={{ fontSize: 44 + "px" }} /> Arrêts de Production</LargeWindowTitle>

                        <table className="scheduling-table">
                            <thead>
                                <tr className="scheduling-table__title">
                                    <th>Roots Affectes</th>
                                    <th>Resume Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    delays.map((e, i) => {
                                        return (
                                            <tr className="scheduling-table__row" key={i}>
                                                <td><input value={e.Description} onChange={e => changeDescHandler(e.target.value, i)}
                                                    type="text" /></td>
                                                <td><DatePicker selected={new Date(e.EndDate)} onChange={(date) => changeDateHandler(date, i)} dateFormat="dd MMMM yyyy" /></td>
                                                <td>
                                                    <span title="Resolve" className="scheduling-table__resolve-icon" onClick={e => resolveHandler(e,i)}>{<DoneIcon style={{fontSize: 45 + "px"}}/>}</span>
                                                </td>
                                            </tr>)
                                    })
                                }
                            </tbody>
                        </table>
                        <div title="Add New Delay" className="scheduling-table__plus" onClick={() => document.querySelector(".scheduling__add-new-background").classList.remove("hidden")}>+</div>
                    </InputsWrap>
                </div>

                <div className="schedulingDataWindow">
                    <InputsWrap>
                        <LargeWindowTitle><div className="timerIcon" /> Disponibilités du service urgent</LargeWindowTitle>
                        <RushServicesTable />
                    </InputsWrap>
                </div>
            </div>

            <div className="scheduling__add-new-background hidden" onClick={(e) => closeAddWindowHandler(e)}>
                <div className="scheduling__add-new-window">
                    <h2>Add New Delay</h2>
                    <div>
                        <label>Description*</label>
                        <input id="schelduling__new-description" value={description} onChange={(e) => addDescriptionHandler(e)} />
                    </div>
                    <div>
                        <label>Start Date*</label>
                        <DatePicker id="schelduling__new-startDate" selected={startDate} onChange={(date) => addStartDateHandler(date)} dateFormat="dd MMMM yyyy" />
                    </div>
                    <div>
                        <label>End Date*</label>
                        <DatePicker id="schelduling__new-endDate" selected={endDate} onChange={(date) => addEndDateHandler(date)} dateFormat="dd MMMM yyyy" />
                    </div>
                    <div>
                        <label>Reason</label>
                        <input id="schelduling__new-reason" list="reasons" value={reason} onChange={(e) => addReasonHandler(e)} />
                        <datalist id="reasons">
                            {reasons.map((e, i) => {
                                return (
                                    <option key={i} value={e} />)
                            })}
                        </datalist>
                    </div>
                    <button className="scheduling__add-new-window__button" onClick={sendNewHandler}>Add</button>

                </div>
            </div>
        </div>
    );
}

export default SchedulingWindow;
