import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { usePerformanceContext } from "../../Context/PerformanceProvider";
function BarGraph({ departmentId, height, position }) {
  let PerformanceContext = usePerformanceContext();
  let [department, setDepartment] = useState(departmentId);
  let [result, setResult] = useState([{}]);
  if (department * 1.0 === 8 && height === 350) {
    height = 300;
  }

  const options = {
    yaxis: {
      min: 0,
      decimalsInFloat: 0,
      forceNiceScale: true,

      labels: {
        style: {
          color: "#008FFB",
        },
      },
      axisTicks: {
        show: true,
      },
    },
    grid: {
      row: {
        colors: undefined,
        opacity: 0.5,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    fill: {
      opacity: 1,
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
      width: [2, 2, 3],
      dashArray: [0, 0],
    },
    colors: ["#3347E6", "#EF5DA8", "#801482"],
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0, 1],
      formatter: function (val, opt) {
        const goals =
          opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex].goals;

        if (goals && goals.length) {
          return `${goals[0].value}`;
        }
        return `${val}%`;
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return `
                <div class="arrow_box">
                 <div class="serie">
                   <div class="morning shift">
                    <div class="values"><p class="serie-title">Morning :</p>  <span class="serie-value"> ${series[0][dataPointIndex]}%</span></div>
                    <div class="loadings"><p class="loading-title">(Loadings : <span>${w.config.series[0].data[dataPointIndex].goals[0].value}<span>)</p> </div>
                  </div>
                 </div>
                 <div class="serie">
                  <div class="evening shift">
                   <div class="values"><p class="serie-title">Evening :</p> <span class="serie-value"> ${series[1][dataPointIndex]}%</span> </div>
                   <div class="loadings"><p class="loading-title">(Loadings : <span>${w.config.series[1].data[dataPointIndex].goals[0].value}<span>)</p> </div>
                  </div>
               </div>
               <div class="serie">
                <div class="daily shift">
                 <div class="values"><p class="serie-title">Daily : </p><span class="serie-value"> ${series[2][dataPointIndex]}%</span> </div>
                 <div class="loadings"><p class="loading-title">(Loadings : <span>${w.config.series[2].data[dataPointIndex].goals[0].value}<span>)</p> </div>
                </div>
               </div>
            </div>
                `;
      },
      title: {
        formatter: (seriesName) => seriesName,
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: "top",
      markers: {
        fillColors: ["#3347E6", "#EF5DA8"],
      },
    },
  };

  const seriesFunc = (param) => {
    let morning = {
      name: "Morning",
      type: "column",
      data: [],
    };
    let evening = {
      name: "Evening ",
      type: "column",
      data: [],
    };
    let daily = {
      name: "Daily ",
      type: "line",
      data: [],
    };
    let def = () => [{ ...morning }, { ...evening }, { ...daily }];
    if (param === undefined || param === null) {
      return def();
    }

    if (Array.isArray(param) && param.length > 0) {
      let morning_dat = [];
      let evening_dat = [];
      let daily_dat = [];
      const morresult = param.map((obj) => ({
        x: obj.Date.substr(
          obj.Date.indexOf("-") + 1,
          obj.Date.indexOf("T") - 5
        ).replace("-", "/"),
        y: obj.MorningPerfPercentDaily,
        goals: [
          {
            name: "Loading",
            value: obj.MorningLoading,
            strokeWidth: 0,
            strokeHeight: 0,
          },
        ],
      }));
      for (const iterator of morresult) {
        morning_dat.push(iterator);
      }
      const everesult = param.map((obj) => ({
        x: obj.Date.substr(
          obj.Date.indexOf("-") + 1,
          obj.Date.indexOf("T") - 5
        ).replace("-", "/"),
        y: obj.EveningPerfPercentDaily,
        goals: [
          {
            name: "Loading",
            value: obj.EveningLoading,
            strokeWidth: 0,
            strokeHeight: 0,
          },
        ],
      }));
      for (const iterator of everesult) {
        evening_dat.push(iterator);
      }
      const dailyresult = param.map((obj) => ({
        x: obj.Date.substr(
          obj.Date.indexOf("-") + 1,
          obj.Date.indexOf("T") - 5
        ).replace("-", "/"),
        y: obj.PerfDaily,
        goals: [
          {
            name: "Loading",
            value: obj.Loading,
            strokeWidth: 0,
            strokeHeight: 0,
          },
        ],
      }));
      for (const iterator of dailyresult) {
        daily_dat.push(iterator);
      }
      morning.data = morning_dat;
      evening.data = evening_dat;
      daily.data = daily_dat;
      return def();
    }
    return def();
  };

  const mapped = Object.values(result);

  useEffect(() => {
    if (
      PerformanceContext.propData !== undefined &&
      PerformanceContext.propData !== null
    ) {
      setResult(PerformanceContext.propData);
    }
  }, [PerformanceContext.propData]);

  return (
    <div
      className="chart-bar"
      style={{
        textAlign: "center",
        paddingBottom: position,
      }}
    >
      <ReactApexChart
        options={options}
        series={seriesFunc(PerformanceContext.propData)}
        type="line"
        height={height}
      />
      {(() => {
        if (departmentId == 8) {
          return (
            <>
              <div className="bottomSetup">
                <span># Setups</span>
                <ul className="setups">
                  {mapped.map((machine, index) => (
                    <li style={{ width: `calc(100%/(${mapped.length + 2})` }}key={index}>
                      {machine.Setup}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="bottomQty">
                <span># Products</span>
                <ul className="qty">
                  {mapped.map((machine, index) => (
                    <li
                      style={{ width: `calc(100%/(${mapped.length + 2})` }}
                      key={index}
                    >
                      {machine.ScannedGrillsQty}
                    </li>
                  ))}
                </ul>
              </div>
            </>
          );
        }
      })()}
    </div>
  );
}

export default BarGraph;
