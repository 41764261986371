import React, { useEffect, useState } from "react";
import useSWR from "swr";
import axios from "axios";
import { SGD_API_HOST } from "../../env";

interface PerformanceDataItem {
  Date: string;
  DepartmentId: number;
  Shift: number;
  Points: number;
  Loading: number;
  PerfDaily: number;
  Last6WeeksPerf: number;
  Last5DaysPerfPercent: number;
  MorningPoints: number;
  MorningLoading: number;
  MorningPerfPercentDaily: number;
  MorningPerfPercentWeekly: number;
  MorningHoursPerShift: number,
  EveningPoints: number;
  EveningLoading: number;
  EveningPerfPercentDaily: number;
  EveningPerfPercentWeekly: number;
  EveningHoursPerShift: number,
  ScannedGrillsQty: number;
  DfQty: number;
  Setup: number;
  Id: string;
  HSWMorning: number;
  HSWEvening: number;
  HSWNight: number;
  HSWMorningId: string;
  HSWEveningId: string;
  HSWNightId: string;
}


const Item: Array<PerformanceDataItem> = [
  {
    Date: "2023-01-09T06:00:00.001927",
    DepartmentId: 8,
    Shift: 0,
    Points: 0,
    Loading: 0,
    PerfDaily: 0,
    Last6WeeksPerf: 0,
    Last5DaysPerfPercent: 0,
    MorningPoints: 0,
    MorningLoading: 0,
    MorningPerfPercentDaily: 0,
    MorningPerfPercentWeekly: 0,
    MorningHoursPerShift: 8,
    EveningPoints: 0,
    EveningLoading: 0,
    EveningPerfPercentDaily: 0,
    EveningPerfPercentWeekly: 0,
    EveningHoursPerShift: 8,
    ScannedGrillsQty: 0,
    DfQty: 0,
    Setup: 0,
    Id: "",
    HSWMorning: 0,
    HSWEvening: 0,
    HSWNight: 0,
    HSWMorningId: "",
    HSWEveningId: "",
    HSWNightId: ""
  },
];

const PerformanceContext = React.createContext({
    propData: {...Item}
  });

  function PerformanceProvider({startDate,endDate,depId, children }){
    let [fetched, setFetched] = useState({...Item});//initialize state
    let [department, setDepartment] = useState(depId);
    let [start, setStart] = useState(startDate);
    let [end, setEnd] = useState(endDate);
    let [isLoading, setLoading] = useState(false)
    useEffect(() => {
        if (department !== depId || start !== startDate || end !== endDate) {
          setDepartment(depId);
          setStart(startDate);
          setEnd(endDate);
          setLoading(false);
        }
    
        if (!isLoading) {    
          setLoading(true);
        }
      }, [isLoading, depId, startDate, endDate,start,end,department]);
    
    const fetcher = url => axios.request({
      url:url,
      method: 'get',
      baseURL:SGD_API_HOST,
      params:{
        startDate:start,
        endDate:end,
        departmentId:department
      },
      //data:{},
      responseType:'json',
    }).then(res => res.data)
   

    const { data } = useSWR('/api/Performance/get-performance-statistic', fetcher, { refreshInterval: 3000 })//fetch data every 10 seconds

    useEffect(()=>{
      //if data has changed, then update state
      if(data !== undefined){
        setFetched(data)
      }
      
    },[data])
    return (
      <PerformanceContext.Provider 
        value={{
          propData: fetched 
        }}>
        {children}
      </PerformanceContext.Provider>
    );
  }
  
  function usePerformanceContext() {
    const context = React.useContext(PerformanceContext);
    if (context === undefined) {
      throw new Error("Error...");
    }
    return context;
  }

  export {PerformanceProvider, usePerformanceContext, PerformanceContext, Item}

