import styled from "styled-components";

const Window = styled.div`
  margin: 40px 0 0px 20px;
  padding: 50px 50px 25px;
  max-width: 700px;
  min-width: 500px;
  background: #ffffff;
  box-shadow: 11.5px 11.5px 35px 11.5px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  justify-content: center;

  @media (max-width: 991px) {
    margin: 40px auto 50px;
  }
  @media (max-width: 900px){
    transform: scale(0.8);
    margin-top: -20px;
  }
`;

const Tile = styled.div`
  margin: 8px 5px;
  padding: 10px;
  width: 145px;
  height: 70px;
  background: #324395;
  color: #fff;
  border-radius: 5px;
  justify-content: center;

  @media (max-width: 1671px) {
    width: 115px;
  }
  @media (max-width: 1073px) {
    width: 145px;
  }

  @media (max-width: 1090px) and (min-width: 992px) {
    height 60px;
    padding: 5px 10px;
  }
`;

const TileText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: auto;
`;

const WindowTitle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  color: #324395;
  margin: 21px auto;
`;
/*
const UpdateLabel = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  text-align: center;
  color: #aaa9a9;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: auto;
`;*/

export { Window, WindowTitle, Tile, TileText };
