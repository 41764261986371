import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom'
import useSWR from 'swr'
import axios from 'axios'
import {useAuth} from 'react-oidc-context'
import {SGD_API_HOST} from '../env'
//SalesGovernanceData, ManufacturingData
interface SalesGovernanceData {
  CurrentWeekFriday: Date,
  WorkingDayAmount:number,
  LaborDollarWeekly:number,
  BacklogCurrent: number,
  BacklogRFSCurrent: number,
  BacklogRFICurrent: number,
  BacklogInvoicedCurrent: number,
  BillingDollarsCurrent: number,
  ProductionCostWeekly: number,
  BookingsWeekly: number,
  BacklogVariationWeekly: number,
  DirectLaborPercentageWeekly: number,
  BillingDailyAverageWeekly: number | null,
  ProductionCostDailyAverageWeekly: number | null,
  BookingsDailyAverageWeekly: number | null,
  BillingsDailyAverageMonthly: number | null,
  ProductionCostDailyAverageMonthly: number | null,
  BookingsDailyAverageMonthly: number | null,
  BacklogVariationDailyAverageMonthly: number | null,
  DirectLaborPercentageMonthly: number | null,
  BaseDirectLaborPercentageMonthly: number | null,
  BillingsDailyAverageQuarterly: number | null,
  ProductionCostDailyAverageQuarterly: number | null,
  BookingsDailyAverageQuarterly: number | null,
  BacklogVariationDailyAverageQuarterly: number | null,
  WeeksToZeroBacklogQuarterlyProjection: number | null,
  WeeksToZeroBacklogMonthlyProjection: number | null,
  DirectLaborPercentageQuarterly: number | null
  BaseDirectLaborPercentageQuarterly: number | null
}

//default object TODO default
const Data = {
  CurrentWeekFriday: new Date("2022-04-18T00:00:00"),
  WorkingDayAmount: 0,
  LaborDollarWeekly: 0,
  BacklogCurrent: 0,
  BacklogRFSCurrent: 0,
  BacklogRFICurrent: 0,
  BacklogInvoicedCurrent: 0,
  BillingDollarsCurrent: 0,
  ProductionCostWeekly: 0,
  BookingsWeekly: 0,
  BacklogVariationWeekly: 0,
  DirectLaborPercentageWeekly :0,
  BillingDailyAverageWeekly: 0,
  ProductionCostDailyAverageWeekly: 0,
  BookingsDailyAverageWeekly: 0,
  BacklogVariationDailyAverageWeekly: 0,
  BillingsDailyAverageMonthly: 0,
  ProductionCostDailyAverageMonthly: 0,
  BookingsDailyAverageMonthly: 0,
  BacklogVariationDailyAverageMonthly: 0,
  WeeksToZeroBacklogMonthlyProjection: 0,
  DirectLaborPercentageMonthly: 0,
  BaseDirectLaborPercentageMonthly: 0,
  BillingsDailyAverageQuarterly: 0,
  ProductionCostDailyAverageQuarterly: 0,
  BookingsDailyAverageQuarterly: 0,
  BacklogVariationDailyAverageQuarterly: 0,
  WeeksToZeroBacklogQuarterlyProjection: 0,
  DirectLaborPercentageQuarterly: 0,
  BaseDirectLaborPercentageQuarterly: 0,
}

const SalesGovernanceContext = React.createContext({
  propData: Data,
  getPage: async (page)=>{ return Data}
});


function SalesGovernanceProvider({ children }){
  let [fetched, setFetched] = useState(Data);//initialize state
  let auth=useAuth();
  let navigate = useNavigate()
  
  const roles = auth.user?.profile?.role as string[];

  const url = roles.includes("Mechanic") 
      ? '/api/GovernanceWeeklyStatistics/getMechanicsGovernanceStatistics/'
      : '/api/GovernanceWeeklyStatistics/getGovernanceStatistics/'

  const fetcher = url => axios.request({
    url:url,
    method: 'get',
    baseURL:SGD_API_HOST,
    headers: {
      Authorization: `bearer ${auth.user?.access_token}`},
    params:{},
    //data:{},
    responseType:'json',
  }).then(res => res.data)
  const { data } = useSWR(url, fetcher, { refreshInterval: 10000 })//fetch data every 10 seconds
  useEffect(()=>{
    //if data has changed, then update state
    navigate('', { replace: true }) //redo
    setFetched(data)
  },[data])

  const getPage = async (page):Promise<typeof Data>=>{
    const res =await fetcher(url)
    return res.json() 
  }
  return (
    <SalesGovernanceContext.Provider
      value={{
        propData: fetched,
        getPage:getPage
      }}>

      {children}
    </SalesGovernanceContext.Provider>
  );
}

function useSalesGovernanceContext() {
  const context = React.useContext(SalesGovernanceContext);
  if (context === undefined) {
    throw new Error("Error...");
  }
  return context;
}

export {SalesGovernanceProvider, useSalesGovernanceContext, SalesGovernanceContext, SalesGovernanceData}