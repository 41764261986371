import React, { useEffect, useState } from "react"
import { useAuth } from "react-oidc-context"
import axios from 'axios'
import { SGD_API_HOST } from '../../env'
import {ReactComponent as PayrollData} from "../../assets/images/PayrollData.svg";
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import { addDays } from "date-fns"
import {
    LargeWindow,
    InputsWrap,
    WindowSelect,
    WindowInput,
    FieldWrap,
    WindowButton,
    WindowLabel,
    LargeWindowTitle,
    LargeWindowSubtitle,
    CalendarIconWrap,
} from "./styles";


function SettingWindow() { // renamed to "Payroll Data"

    //all weeks need to fill
    let [weeks, setWeeks] = useState([])

    // current week, payroll, days
    let [CurrentWeekFriday, setWeek] = useState()
    let [WorkingDaysAmount, setDays] = useState()
    let [HoursWorkedAmountREG, setHoursREG] = useState()
    let [HoursWorkedAmountOT, setHoursOT] = useState()
    let [TotalLaborDollarsWeekly, setTotalLabor] = useState()
    let [BaseLaborDollarsWeekly, setBaseLabor] = useState()
    let [OvertimeDeltaDollarsWeekly, setOvertimeDelta] = useState()
    let [VacayStatDollarsWeekly, setVacayStat] = useState()
    let [BonusesDollarsWeekly, setBonuses] = useState()

    let auth = useAuth();

    const InputFields = {
        CurrentWeekFriday: "CurrentWeekFriday",
        WorkingDaysAmount: "WorkingDaysAmount",
        HoursWorkedAmountREG: "HoursWorkedAmountREG",
        HoursWorkedAmountOT: "HoursWorkedAmountOT",
        TotalLaborDollarsWeekly: "TotalLaborDollarsWeekly",
        BaseLaborDollarsWeekly: "BaseLaborDollarsWeekly",
        OvertimeDeltaDollarsWeekly: "OvertimeDeltaDollarsWeekly",
        VacayStatDollarsWeekly: "VacayStatDollarsWeekly",
        BonusesDollarsWeekly: "BonusesDollarsWeekly",
    }

    const CleanInputsAndData = () => {
        setDays('')
        setHoursREG('')
        setHoursOT('')
        setTotalLabor('')
        setBaseLabor('')
        setOvertimeDelta('')
        setVacayStat('')
        setBonuses('')
        for (const key in InputFields) {
            if (Object.hasOwnProperty.call(InputFields, key) && document.querySelector("#" + InputFields[key])) {
                document.querySelector("#" + InputFields[key]).value = ''
            }
        }
    }

    //GET request to get add dates
    useEffect(() => {
        axios.get(SGD_API_HOST + '/api/GovernanceWeeklyStatistics/getRowsToSetPayrollAndWorkingDays', {
            headers: {
                Authorization: `bearer ${auth.user?.access_token}`
            },
        }).then(function (response) {
            setWeeks(response.data)

            setWeek((response.data.sort())[0]) // set week to the oldest week

            if (response.data.length == 0) { // if all data is filled
                document.querySelector("#InsertTheData").innerHTML = "All data is filled"
                document.querySelector("#SelectDate").style.color = "#AAA9A9"
                var payrollFields = document.querySelectorAll(".payrollField");
                payrollFields.forEach(function(element) {
                    element.classList.add("hidden");
                  });
            }

            document.querySelector("#loadingText").classList.add("hidden")
            document.querySelector("#inputsBlock").classList.remove("hidden")
        })
    })

    //handle form submit and send POST request
    const handleSubmit = (params) => {
        if (!CurrentWeekFriday || !WorkingDaysAmount || !HoursWorkedAmountREG || !HoursWorkedAmountOT || !TotalLaborDollarsWeekly
            || !BaseLaborDollarsWeekly || !OvertimeDeltaDollarsWeekly || !VacayStatDollarsWeekly || !BonusesDollarsWeekly) {
            params.preventDefault()
            alert("Incorrect data")
        }
        else {
            params.preventDefault()

            document.querySelector("#inputsBlock").classList.add("hidden")
            document.querySelector("#loadingText").classList.remove("hidden")

            const data = {
                CurrentWeekFriday,
                WorkingDaysAmount,
                HoursWorkedAmountREG,
                HoursWorkedAmountOT,
                TotalLaborDollarsWeekly,
                BaseLaborDollarsWeekly,
                OvertimeDeltaDollarsWeekly,
                VacayStatDollarsWeekly,
                BonusesDollarsWeekly
            }

            fetch(SGD_API_HOST + '/api/GovernanceWeeklyStatistics/SetPayrollAndWorkingDays', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${auth.user?.access_token}`,
                },
                body: JSON.stringify(data)
            }).then((response) => {
                alert("Data saved")
                CleanInputsAndData()

                //trigger handleInput 
                var event = new Event('input', {
                    bubbles: true,
                    cancelable: true,
                });

                for (const key in InputFields) {
                    if (Object.hasOwnProperty.call(InputFields, key) && document.querySelector("#" + InputFields[key])) {
                        document.querySelector("#" + InputFields[key]).dispatchEvent(event)
                    }
                }
            })
        }
    }

    //handle input event
    const handleInput = (e) => {
        if (e.target.value) {
            e.target.previousElementSibling.classList.remove("hidden")
            e.target.style.paddingLeft = 42 + e.target.previousElementSibling.offsetWidth + "px"
        } else {
            e.target.previousElementSibling.classList.add("hidden")
            e.target.style.paddingLeft = "32px"
        }
    }

    //format date to "2 Jun 2022"
    const getFormattedDate = (e) => {
        const month = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ]
        return e.getDate() + ' ' + month[e.getMonth()] + ' ' + e.getFullYear()
    }

    return (
        <div>
            <div id="loadingText">Loading...</div>
            <div id="inputsBlock" class="hidden">
                <LargeWindow>
                    <InputsWrap onSubmit={handleSubmit}>
                        <LargeWindowTitle><PayrollData fill="#324395"/> Payroll Data</LargeWindowTitle>
                        <LargeWindowSubtitle id="InsertTheData">Enter the week’s payroll data</LargeWindowSubtitle>

                        
                        <FieldWrap className="payrollField">
                            <CalendarIconWrap><CalendarIcon style={{ fontSize: 28 + "px" }} /></CalendarIconWrap>
                            <WindowSelect id="SelectDate">
                                {!weeks.length ? <option disabled selected>Choose period</option> : ''}
                                {
                                    weeks.sort().map((e, i) => {
                                        if (i == 0)
                                            return <option value={e}>{getFormattedDate(addDays(new Date(e), -7))} - {getFormattedDate(addDays(new Date(e), -1))}</option>
                                        return <option disabled value={e}>{getFormattedDate(addDays(new Date(e), -7))} - {getFormattedDate(addDays(new Date(e), -1))}</option>
                                    }
                                    )
                                }
                            </WindowSelect>
                        </FieldWrap>
                        
                        <FieldWrap className="payrollField">
                            <WindowLabel className="hidden">$</WindowLabel>
                            <WindowInput id={InputFields.OvertimeDeltaDollarsWeekly} onInput={handleInput} onChange={(e) => { setOvertimeDelta(e.target.value) }}
                                type="number" min="0" placeholder="Overtime Δ weekly $"></WindowInput>
                        </FieldWrap>

                        <FieldWrap className="payrollField">
                            <WindowLabel className="hidden">$</WindowLabel>
                            <WindowInput id={InputFields.VacayStatDollarsWeekly} onInput={handleInput} onChange={(e) => { setVacayStat(e.target.value) }}
                                type="number" min="0" placeholder="Vacay+Stat weekly $"></WindowInput>
                        </FieldWrap>

                        <FieldWrap className="payrollField">
                            <WindowLabel className="hidden">$</WindowLabel>
                            <WindowInput id={InputFields.BonusesDollarsWeekly} onInput={handleInput} onChange={(e) => { setBonuses(e.target.value) }}
                                type="number" min="0" placeholder="Bonuses weekly $"></WindowInput>
                        </FieldWrap>

                        <FieldWrap className="payrollField">
                            <WindowLabel className="hidden">$</WindowLabel>
                            <WindowInput id={InputFields.BaseLaborDollarsWeekly} onInput={handleInput} onChange={(e) => { setBaseLabor(e.target.value) }}
                                type="number" min="0" placeholder="Base payroll weekly $"></WindowInput>
                        </FieldWrap>

                        <FieldWrap className="payrollField">
                            <WindowLabel className="hidden">$</WindowLabel>
                            <WindowInput id={InputFields.TotalLaborDollarsWeekly} onInput={handleInput} onChange={(e) => { setTotalLabor(e.target.value) }}
                                type="number" min="0" placeholder="Total payroll weekly $"></WindowInput>
                        </FieldWrap>

                        <FieldWrap className="payrollField">
                            <WindowLabel className="hidden">№ hours</WindowLabel>
                            <WindowInput id={InputFields.HoursWorkedAmountREG} onInput={handleInput} onChange={(e) => setHoursREG(e.target.value)}
                                type="number" min="0" placeholder="Number of hours worked - REG"></WindowInput>
                        </FieldWrap>

                        <FieldWrap className="payrollField">
                            <WindowLabel className="hidden">№ hours</WindowLabel>
                            <WindowInput id={InputFields.HoursWorkedAmountOT} onInput={handleInput} onChange={(e) => setHoursOT(e.target.value)}
                                type="number" min="0" placeholder="Number of hours worked - OT"></WindowInput>
                        </FieldWrap>

                        <FieldWrap className="payrollField">
                            <WindowLabel className="hidden">№ days</WindowLabel>
                            <WindowInput id={InputFields.WorkingDaysAmount} onInput={handleInput} onChange={(e) => setDays(e.target.value)}
                                type="number" min="0" max="7" placeholder="Number of work days"></WindowInput>
                        </FieldWrap>
                        
                        <WindowButton className="payrollField" type="submit">SAVE</WindowButton>
                        
                    </InputsWrap>
                </LargeWindow>
            </div>
        </div>
    );
}

export default SettingWindow;